import { withRouter } from "react-router-dom";
import React, { Fragment } from "react";
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import info from '../../assets/img/bi-info-circle.svg';
import nacs from "../../assets/img/nacs.png";
import speedmeter from '../../assets/img/clock.svg';
import Charger from '../../assets/img/Frame 2400.png';
import Tesla from '../../assets/img/Tesla.svg'
import home from '../../assets/img/car-home.png'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { NumberWithThousandSeparator, isEmpty, handleDragStart, handleContextMenu } from "../../helper/project_helper";
import ClosestChargingStation from "../../components/ClosestChargingStation";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ChargingSection = ({
    chargingDetail,
    chargingIncentives,
    tooltipText
}) => {
    return (
        <section className="mx-lg-5 mx-3">
            <Row className="g-4">
                <Col lg={6}>
                    {(chargingDetail && (chargingDetail?.is_tesla_supercharger_accessible !== null || chargingDetail?.is_nacs_compatible !== null)) && (
                        <div className="main-text-detail p-3 white-bg mb-4 mt-4">
                            {chargingDetail?.is_tesla_supercharger_accessible !== null && (
                                <div className="d-flex align-items-center mb-3">
                                    <img width={22} className="me-2"
                                        src={Tesla}
                                        onContextMenu={handleContextMenu}
                                        onDragStart={handleDragStart}
                                        alt="Tesla"
                                        
                                        />
                                    <span className="font-midium font-wxl font-black">Tesla Supercharger Accessible

                                        {!isEmpty(tooltipText?.[9]) &&
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip className="custom-tooltip ">
                                                        <div className="tc-text">
                                                            {tooltipText?.[9]}
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <svg className="ms-1 me-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                    <path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z" fill="#21272A" />
                                                    <path d="M6.69746 4.941L4.97996 5.15625L4.91846 5.44125L5.25596 5.5035C5.47646 5.556 5.51996 5.6355 5.47196 5.85525L4.91846 8.45625C4.77296 9.129 4.99721 9.4455 5.52446 9.4455C5.93321 9.4455 6.40796 9.2565 6.62321 8.997L6.68921 8.685C6.53921 8.817 6.32021 8.8695 6.17471 8.8695C5.96846 8.8695 5.89346 8.72475 5.94671 8.46975L6.69746 4.941ZM6.74996 3.375C6.74996 3.57391 6.67095 3.76468 6.53029 3.90533C6.38964 4.04598 6.19888 4.125 5.99996 4.125C5.80105 4.125 5.61029 4.04598 5.46963 3.90533C5.32898 3.76468 5.24996 3.57391 5.24996 3.375C5.24996 3.17609 5.32898 2.98532 5.46963 2.84467C5.61029 2.70402 5.80105 2.625 5.99996 2.625C6.19888 2.625 6.38964 2.70402 6.53029 2.84467C6.67095 2.98532 6.74996 3.17609 6.74996 3.375Z" fill="#21272A" />
                                                </svg>
                                            </OverlayTrigger>}
                                        {chargingDetail?.is_tesla_supercharger_accessible === true ? "Yes" : "No"}
                                    </span>
                                </div>
                            )}
                            {chargingDetail?.is_nacs_compatible !== null && (
                                <div className="d-flex align-items-center ">
                                    <img
                                        className="me-2"
                                        src={nacs}
                                        onContextMenu={handleContextMenu}
                                        onDragStart={handleDragStart}
                                        alt="" />
                                    <span className="font-midium font-wxl font-black">NACS Compatible
                                        {!isEmpty(tooltipText?.[10]) &&
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip className="custom-tooltip ">
                                                        <div className="tc-text">
                                                            {tooltipText?.[10]}
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <svg className="ms-1 me-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                    <path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z" fill="#21272A" />
                                                    <path d="M6.69746 4.941L4.97996 5.15625L4.91846 5.44125L5.25596 5.5035C5.47646 5.556 5.51996 5.6355 5.47196 5.85525L4.91846 8.45625C4.77296 9.129 4.99721 9.4455 5.52446 9.4455C5.93321 9.4455 6.40796 9.2565 6.62321 8.997L6.68921 8.685C6.53921 8.817 6.32021 8.8695 6.17471 8.8695C5.96846 8.8695 5.89346 8.72475 5.94671 8.46975L6.69746 4.941ZM6.74996 3.375C6.74996 3.57391 6.67095 3.76468 6.53029 3.90533C6.38964 4.04598 6.19888 4.125 5.99996 4.125C5.80105 4.125 5.61029 4.04598 5.46963 3.90533C5.32898 3.76468 5.24996 3.57391 5.24996 3.375C5.24996 3.17609 5.32898 2.98532 5.46963 2.84467C5.61029 2.70402 5.80105 2.625 5.99996 2.625C6.19888 2.625 6.38964 2.70402 6.53029 2.84467C6.67095 2.98532 6.74996 3.17609 6.74996 3.375Z" fill="#21272A" />
                                                </svg>
                                            </OverlayTrigger>}
                                        {chargingDetail?.is_nacs_compatible === true ? "Yes" : "No"}
                                    </span>
                                </div>
                            )}
                        </div>)}
                    <div className="main-text-detail p-3 white-bg mb-md-4 mt-4">
                        <h5 className="font-w font-px font-black">Charging Stations Near Me</h5>
                        <ClosestChargingStation />
                    </div>

                </Col>
                {chargingDetail &&
                    <Col lg={6}>
                        <div className="main-text-detail p-3 white-bg mt-md-4">
                            <h5 className="mb-4 font-w font-px font-Gunmetal">Home Charging</h5>
                            <Row>
                                <Col lg={4} md={4}>
                                    <div className="img-respons mb-2">
                                        <img
                                            src={process.env.REACT_APP_HOME_CHARGER_BASE_URL + chargingDetail?.home_charger_image_link} 
                                            // alt={chargingDetail?.home_charger_image_link}
                                            alt=""
                                            onContextMenu={handleContextMenu}
                                            onDragStart={handleDragStart}
                                        />
                                    </div>
                                    <span style={{ whiteSpace: "nowrap" }} className="font-small font-wxl font-black d-flex justify-content-center">Source: {chargingDetail?.home_charger_image_source}</span>
                                </Col>
                                <Col lg={8} md={8}>
                                    <h4 className="text-center font-Gunmetal font-largexl font-w8">{chargingDetail?.home_charger_brand && chargingDetail?.home_charger_brand}</h4>
                                    <div className="d-flex justify-content-evenly text-center my-4">
                                        <div></div>
                                        <div></div>
                                        <div className="d-flex flex-column">
                                            <span className="font-midium font-wxl font-black">{chargingDetail?.home_charger_price !== null && chargingDetail?.home_charger_price > 0 ? "$"+NumberWithThousandSeparator(chargingDetail?.home_charger_price) : "N/A"}</span>
                                            <span className="font-small font-w4 font-black">Retail Price</span>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="font-midium font-wxl font-black">{chargingDetail?.home_charger_installation_cost !== null && chargingDetail?.home_charger_installation_cost != 0 ? "$"+NumberWithThousandSeparator(chargingDetail?.home_charger_installation_cost) : "N/A"}</span>
                                            <span className="font-small font-w4 font-black">Avg. Installation Price</span>
                                        </div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <h5 className="text-center font-midium font-wxl font-black">Compatible with other EVs? {chargingDetail?.is_home_charger_compatible === true ? "Yes" : "No"}</h5>
                                    <div className="d-flex flex-column" style={{ marginTop: "2.25rem" }}>
                                        {!isEmpty(chargingDetail?.home_charger_link) && (
                                            <a className="btn-learn-more text-center" target="_blank" rel="noopener noreferrer" href={chargingDetail?.home_charger_link}>{chargingDetail?.make} Charging
                                                <FontAwesomeIcon icon={faUpRightFromSquare}
                                                    className="ms-3"
                                                />
                                            </a>)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4">
                            <Row className="g-4">
                                <Col lg={12} md={12}>
                                    {(!isEmpty(chargingDetail?.dc_fast_charge_speed) || !isEmpty(chargingDetail?.dc_fast_charge_time) || !isEmpty(chargingDetail?.dc_fast_charge_speed) || !isEmpty(chargingDetail?.ac_charge_time)) &&
                                        <div className="main-text-detail p-3 white-bg">
                                            <h5 className="mb-4 font-w font-px font-Gunmetal">Charging Time</h5>
                                            <Row>
                                                {(!isEmpty(chargingDetail?.dc_fast_charge_speed) || !isEmpty(chargingDetail?.dc_fast_charge_time) || !isEmpty(chargingDetail?.dc_fast_charge_percent)) &&
                                                    <Col lg={5} md={5} sm={5} xs={6}>
                                                        <div className="charging-w text-center">
                                                            <h6 className="font-midium font-w4 font-black m-0">Fast Charging (DC)</h6>
                                                            <p className="font-midium font-w4 font-black" style={{ textAlign: "left", marginLeft: "0.10rem" }}>Level 3</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between flex-wrap mb-2">
                                                            {!isEmpty(chargingDetail?.dc_fast_charge_speed) &&
                                                                <div>
                                                                    <span className="font-small font-wxl font-black">Up to</span>
                                                                    <h5 className="me-1 font-w8 font-largeX font-black">{!isEmpty(chargingDetail?.dc_fast_charge_speed.split(' miles')[0]) && chargingDetail?.dc_fast_charge_speed.split(' miles')[0]}<sub className="font-small font-wxl font-black">&nbsp;mi</sub></h5>
                                                                    <span className="font-small font-wxl font-black">{chargingDetail?.dc_fast_charge_speed.split(' miles')[1] ? chargingDetail?.dc_fast_charge_speed.split(' miles')[1] : ""}</span>
                                                                </div>
                                                            }
                                                            {isEmpty(chargingDetail?.dc_fast_charge_speed) && !isEmpty(chargingDetail?.dc_fast_charge_time) && !isEmpty(chargingDetail?.dc_fast_charge_percent) &&
                                                                <div>
                                                                    <span className="font-small font-wxl font-black">About</span>
                                                                    <h5 className="font-w8 font-largeX font-black">{chargingDetail?.dc_fast_charge_time} <sub style={{ marginLeft: "-5px" }} className="font-small font-wxl font-black">mins</sub></h5>
                                                                    {/* <span className="font-small font-wxl font-black">to go from</span><br/> */}
                                                                    <span className="font-small font-wxl font-black">{chargingDetail?.dc_fast_charge_percent}</span>
                                                                    {/* <h5 className="me-1 font-w8 font-largeX font-black">{chargingDetail?.dc_fast_charge_percent}</h5>
                                                                    <span className="font-small font-wxl font-black">in {chargingDetail?.dc_fast_charge_time} mins</span> */}
                                                                </div>
                                                            }
                                                        </div>
                                                        {!isEmpty(chargingDetail?.vehicle_peak_charge_rate) &&
                                                            <span className="font-small font-wxl font-Gunmetal">Peak Charge Rate&nbsp;&nbsp;<span className="font-black">{chargingDetail?.vehicle_peak_charge_rate}</span></span>
                                                        }

                                                    </Col>
                                                }
                                                {/* {(!isEmpty(chargingDetail?.dc_fast_charge_speed) || !isEmpty(chargingDetail?.dc_fast_charge_time) || !isEmpty(chargingDetail?.dc_fast_charge_percent)) &&
                                                    <Col lg={1} md={1}>
                                                    </Col>} */}
                                                {(!isEmpty(chargingDetail?.ac_charge_speed) || !isEmpty(chargingDetail?.ac_charge_time) || !isEmpty(chargingDetail?.ac_charge_percent)) &&
                                                    <Col lg={5} md={5} sm={5} xs={6}>
                                                        <div className="home-charging-w text-center">
                                                            <h6 className="font-midium font-w4 font-black m-0">Home Charging (AC)</h6>
                                                            <p className="font-midium font-w4 font-black" style={{ textAlign: "left", marginLeft: "0.10rem" }}>Level 2</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between flex-wrap mb-2">
                                                            {(!isEmpty(chargingDetail?.ac_charge_speed)) &&
                                                                <div>
                                                                    <span className="font-small font-wxl font-black">Up to</span>
                                                                    <h5 className="me-1 font-w8 font-largeX font-black">{!isEmpty(chargingDetail?.ac_charge_speed.split(' miles')[0]) && chargingDetail?.ac_charge_speed.split(' miles')[0]}<sub className="font-small font-wxl font-black">&nbsp;mi</sub></h5>
                                                                    <span className="font-small font-wxl font-black">in 60 mins</span>
                                                                </div>
                                                            }
                                                            {(isEmpty(chargingDetail?.ac_charge_speed) && !isEmpty(chargingDetail?.ac_charge_time) && !isEmpty(chargingDetail?.ac_charge_percent)) &&
                                                                <div>
                                                                    <span className="font-small font-wxl font-black">About</span>
                                                                    <h5 className="me-1 font-w8 font-largeX font-black">
                                                                        {!isEmpty(chargingDetail?.ac_charge_time.split(' hrs')[0]) && chargingDetail?.ac_charge_time.includes("hrs") &&
                                                                            <Fragment>
                                                                                {chargingDetail?.ac_charge_time.split(' hrs')[0]}
                                                                                <sub className="font-small font-wxl font-black">&nbsp;hrs</sub>
                                                                            </Fragment>
                                                                        }



                                                                        {!isEmpty(chargingDetail?.ac_charge_time.split(' hrs')[1]) ?
                                                                            !isEmpty(chargingDetail?.ac_charge_time.split(' hrs')[1].split(' mins')[0]) && chargingDetail?.ac_charge_time.split(' hrs')[1].split(' mins')[0] :
                                                                            chargingDetail?.ac_charge_time.includes('mins') &&
                                                                            chargingDetail?.ac_charge_time.split(' mins')[0]
                                                                        }
                                                                        {chargingDetail?.ac_charge_time.includes('mins') &&
                                                                            <sub className="font-small font-wxl font-black">&nbsp;mins</sub>}
                                                                    </h5>
                                                                    <span className="font-small font-wxl font-black" style={{ marginTop: "-1%" }}>{chargingDetail?.ac_charge_percent}</span>
                                                                    {/* <h6 className="me-1 font-w8 font-largeX font-black">{chargingDetail?.ac_charge_percent}</h6>
                                                                    <span className="font-small font-wxl font-black">in {chargingDetail?.ac_charge_time}</span> */}
                                                                </div>

                                                                // <div style={{ position: 'relative', width: '60px', height: '60px' }}>
                                                                //     <img style={{ padding: "2px" }} src={speedmeter} alt="" />
                                                                //     <div style={{ position: 'absolute', top: '0px' }}>
                                                                //         <CircularProgressbar
                                                                //             maxValue={24}
                                                                //             value={chargingDetail?.ac_charge_time}
                                                                //             textForPercentage={null}
                                                                //             strokeWidth={2}
                                                                //         />
                                                                //     </div>
                                                                //     <div className="speedmeter d-flex flex-column align-items-center">
                                                                //         <span className="font-small font-w line-h">{chargingDetail?.ac_charge_time}</span>
                                                                //         <span className="font-small font-w">hrs</span>
                                                                //     </div>
                                                                // </div>
                                                            }
                                                        </div>
                                                        {(!isEmpty(chargingDetail?.ac_charger_outlet)
                                                        ) &&
                                                            <span className="font-small font-wxl font-Gunmetal">AC Charger Outlet&nbsp;&nbsp;<span className="font-black">{chargingDetail?.ac_charger_outlet}</span></span>
                                                        }
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    }
                                </Col>
                                {/* {!isEmpty(chargingDetail?.bi_directional_charging) &&
                                    <Col lg={4} md={12}>
                                        <div className="main-text-detail p-3 white-bg pb-5">
                                            <h5 className="font-w font-px font-Gunmetal mb-4">Bi-Directional Charging</h5>
                                            {chargingDetail?.bi_directional_charging?.split(',')?.map((val, k) => {
                                                return (
                                                    <div className="d-flex flex-column">
                                                        <span className="font-small font-wxl font-black">{val}</span>
                                                        <div className="mb-2">
                                                            <img className="pb-4" src={process.env.REACT_APP_BI_CHARGING_BASE_URL + val + ".svg"} alt="" />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Col>
                                } */}
                            </Row>
                        </div>
                    </Col>
                }
            </Row>
        </section >
    )
}

export default withRouter(ChargingSection);