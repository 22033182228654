
import React, { useState, useMemo, useEffect, Fragment, useCallback } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEVIncentiveDetailLoading, updateEVIncentiveDetail, updateAuthPoupState, updateUserDetails } from "../../store/action";
import { clearLoginUserData, setLoginUserData } from "../../helper/sessionStorage";
import * as apiHelper from "../../helper/api_helper";
import TabSection from "./TabSection";
import ModelDetailSection from "./ModelDetailSection";
import ChargingSection from "./ChargingSection";
import { defaultPopUpState, ArrayAsc, isEmpty, DateFormatterLastUpdated, createSeoFriendlyUrl } from '../../helper/project_helper';
import { useAuth, useEVIncentiveDetailSelectedState } from "../../components/hooks/useAuth";
import { withRouter } from "react-router-dom";
import DetailLoder from "../../components/SkeletonLoder/DetailLoder";
import PageLoaderComponent from "../../components/PageLoaderComponent";

const defaultValueIncentiveDetail = (make, model, model_year, trim, userData) => {
    let tax_filing_status = '';
    let household_income = '';

    // if (!isEmpty(userData?.user_attributes?.tax_filing_status)) {
    //     tax_filing_status = userData?.user_attributes?.tax_filing_status;
    // }
    // if (!isEmpty(userData?.user_attributes?.annual_household_income_limit)) {
    //     household_income = userData?.user_attributes?.annual_household_income_limit;
    // }

    return {
        make: make || "",
        model: model || "",
        model_year: model_year || "",
        trim: trim || "",
        tax_filing_status: tax_filing_status,
        household_income: household_income,
        checkEligibility: false,
        lease_term: '',
        annual_mileage: ''
    };
};

const EVIncentiveDetail = ({
    history
}) => {
    let { vehicle_id, purchase_type } = useParams();
    const [defaultValues, setDefaultValues] = useState(false);
    const [defaultValuesIsSet, setDefaultValuesIsSet] = useState(false);
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [model_year, setmodelYear] = useState('');
    const [incentiveCheckState, updateIncentiveState] = useEVIncentiveDetailSelectedState();

    const dispatch = useDispatch();
    const [isLogin, userData] = useAuth();

    const [first, setFirst] = useState(true);
    const [firstCall, setFirstCall] = useState(true);
    const [firstTrim, setFirstTrim] = useState(true);
    const [displaySkeletonView, setDisplaySkeletonView] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [filter, setFilter] = useState({});
    const [incentiveAmount, setIncentiveAmount] = useState(0);
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedMoreFeature, setExpandedMoreFeature] = useState(false);
    const [currentTab, setCurrentTab] = useState(purchase_type);
    const [specialIncentives, setSpecialIncentives] = useState(0);
    const [totalSavingAmount, settotalSavingAmount] = useState(0);
    const [incentiveLeaseDetailData, setIncentiveLeaseDetailData] = useState(false);
    const [lastTrim, setLastTrim] = useState('');
    const [displayAGI, setDisplayAGI] = useState(false);


    if (isEmpty(purchase_type)) {
        purchase_type = 'Buy';
    }


    async function GetVehicleData() {
        dispatch(setEVIncentiveDetailLoading(true));
        try {
            const params = {}
            params.vehicle_id = vehicle_id;
            const buyResponse = await apiHelper.postGetVehicle(params);
            if (buyResponse.status === 200) {
                if (buyResponse.data.status === 1) {
                    const vehicleData = buyResponse.data.body;
                    setModel(vehicleData?.model);
                    setTrim(vehicleData?.trim);
                    setmodelYear(vehicleData?.model_year);
                    setMake(vehicleData?.make);
                    const defaultFil = defaultValueIncentiveDetail(vehicleData?.make, vehicleData?.model, String(vehicleData?.model_year), vehicleData?.trim, userData);
                    setDefaultValues(defaultFil);
                    setFilter(defaultFil);
                    setDefaultValuesIsSet(false);
                    dispatch(setEVIncentiveDetailLoading(false));
                }else if (buyResponse.data.status === 3) {
                    history.push("/", true)
                    dispatch(setEVIncentiveDetailLoading(false));
                }
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }
    
    useEffect(() => {
        GetVehicleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle_id]);

   
    const { stateIncentiveDetail } = useSelector((state) => ({
        stateIncentiveDetail: state.EVIncentiveDetail,
    }));

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState
    }));

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData]);

    const onLogin = (x) => {

    }

    useEffect(() => {
        if (isLogin) {
            setDefaultValues(defaultValueIncentiveDetail(make, model, model_year, trim, userData))
            setFilter(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    if (first === true) {
        if (userData === false || userData === undefined) {
            askLogin();
        }
        setSpecialIncentives(0);

        updateIncentiveState({
            selectedIncentives: [],
            otherIncentives: []
        });

        dispatch(updateEVIncentiveDetail({
            loading: false,
            status: true,
            buyData: false,
            filter: false
        }));
        setFirst(false);
    }

    const incentiveBuyDetailData = useMemo(() => {

        if (stateIncentiveDetail.buyData !== false) {
            return stateIncentiveDetail.buyData;
        }
        return null;
    }, [stateIncentiveDetail]);

    const incentiveLeaseDetailDataState = useMemo(() => {

        if (stateIncentiveDetail.leaseData !== false) {
            return stateIncentiveDetail.leaseData;
        }
        return null;
    }, [stateIncentiveDetail]);


    const page_title = useMemo(() => {
        if (incentiveBuyDetailData !== false) {
            if (incentiveBuyDetailData?.page_title !== null) {
                return incentiveBuyDetailData?.page_title;
            }
        }
        return null;
    }, [incentiveBuyDetailData]);

    const page_meta_description = useMemo(() => {
        if (incentiveBuyDetailData !== false) {
            if (incentiveBuyDetailData?.page_meta_description !== null) {
                return incentiveBuyDetailData?.page_meta_description;
            }
        }
        return null;
    }, [incentiveBuyDetailData]);



    useEffect(() => {
        if (!isEmpty(page_title)) {
            document.title = page_title;
        }
        if (!isEmpty(page_meta_description)) {
            document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page_title, page_meta_description]);


    const onUpdate = (name, value1) => {
        let value_ = { ...filter };
        value_[name] = value1;
        setFilter(value_);
    }

    const onReset = () => {
        const temp = { ...filter }
        setFilter({ ...temp, tax_filing_status: '', household_income: "", checkEligibility: false });
        // setSpecialIncentives(0);
        // settotalSavingAmount(parseFloat(incentiveBuyDetailData?.buy?.total_saving_amount))
        // const otherInc = [];
        // incentiveBuyDetailData?.buy?.specialIncentives?.map((incentivesVal, incentivesKey) => {
        //     otherInc.push(incentivesVal?.incentive_id);
        // });

        // updateIncentiveState({
        //     selectedIncentives: [],
        //     otherIncentives: otherInc
        // });
    }

    var isLoading = useMemo(() => {
        return stateIncentiveDetail.loading || false;
    }, [stateIncentiveDetail]);

    useEffect(() => {
        // if (userData?.tax_filing_status == filter?.tax_filing_status) {
        //     setFilter({ ...filter, household_income: userData?.household_income });
        // } else {
        if (filter?.tax_filing_status !== '') {
            setFilter({ ...filter, household_income: "1" });
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.tax_filing_status]);

    useEffect(() => {
        if (stateIncentiveDetail?.loading === false && stateIncentiveDetail?.error === '' && isLogin && firstCall === false) {
            getBuyIncentiveDetail(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.trim, filter.checkEligibility]);
    
    useEffect(() => {
        
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin && !isEmpty(make)) {
            getBuyIncentiveDetail(filter, true);
            setFirstCall(false);
        }
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin && !isEmpty(make)) {
            getLeaseIncentiveDetail(filter, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin,filter?.make]);

    useEffect(() => {
        setFilter(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    useEffect(() => {
        if (stateIncentiveDetail.leaseData !== false && stateIncentiveDetail.buyData !== false && isLogin && displaySkeletonView === true && !isLoading) {
            setTimeout(() => {
                setDisplaySkeletonView(false);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIncentiveDetail]);

    useEffect(() => {
        console.log();
        
        if (firstTrim === false) {
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== undefined && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== null && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== false) {
                history.push('/vehicledetail/' + purchase_type + "/" + stateIncentiveDetail?.buyData?.buy?.vehicle_id  + "/" + createSeoFriendlyUrl(filter?.make) + "/" + createSeoFriendlyUrl(filter?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(filter?.trim), null, { shallow: true });
            }
        } else {
            setFirstTrim(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.trim, stateIncentiveDetail?.buyData?.buy?.vehicle_id]);

    useEffect(() => {
        // if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin) {
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin) {
            const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveLeaseDetailDataState }));
            if (filter?.lease_term !== '' && filter?.annual_mileage !== '' && tempIncentiveLeaseDetailDataState?.vehicle_lease_data !== undefined) {

                const index = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.findIndex(item => String(item?.lease_term) === String(filter?.lease_term) && String(item?.annual_mileage) === String(filter?.annual_mileage));

                if (index !== -1) {
                    tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.[index];
                } else {

                    tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = false;

                }
                setIncentiveLeaseDetailData(tempIncentiveLeaseDetailDataState);
            }
        }

        // console.log(tempLeaseData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.lease_term, filter?.annual_mileage]);

    useEffect(() => {
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin) {
            if (filter?.trim !== lastTrim && lastTrim !== '') {
                getLeaseIncentiveDetail(filter);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.trim]);

    async function getBuyIncentiveDetail(params = {}, dontCheckFilter = false) {

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }

        dispatch(setEVIncentiveDetailLoading(true));
        setDisplayAGI(false);
        try {
            params.purchase_type = 'Buy';
            const buyResponse = await apiHelper.postGetEVIncentiveDetail(params);
            var incentiveDetail = false;
            if (buyResponse.status === 200) {
                if (buyResponse.data.status === 1) {
                    incentiveDetail = buyResponse.data.body;

                    if (incentiveDetail?.user_attributes !== undefined && incentiveDetail?.user_attributes !== null && incentiveDetail?.user_attributes !== undefined) {
                        if (filter?.checkEligibility === true) {
                            let tempUserData = { ...userData };
                            tempUserData.user_attributes = incentiveDetail?.user_attributes;
                            setLoginUserData(tempUserData);
                        }
                    }
                    setLastTrim(filter?.trim);
                    dispatch(updateEVIncentiveDetail({
                        loading: false,
                        status: true,
                        buyData: incentiveDetail,
                        filter: filter
                    }));


                    setTimeout(() => {
                        setDisplayAGI(true);
                    }, 100);

                } else if (buyResponse.data.status === 2) {
                    dispatch(setEVIncentiveDetailLoading(false));
                    askLogin();
                } else {
                    dispatch(updateEVIncentiveDetail({
                        loading: false,
                        status: true,
                        error: buyResponse?.data
                    }));
                }
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    async function getLeaseIncentiveDetail(params = {}, dontCheckFilter = false) {

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }

        dispatch(setEVIncentiveDetailLoading(true))
        try {
            var incentiveDetail = false;
            params.purchase_type = 'Lease';
            const leaseResponse = await apiHelper.postGetEVIncentiveDetail(params);
            if (leaseResponse.status === 200) {
                if (leaseResponse?.data?.status === 1) {
                    incentiveDetail = leaseResponse?.data?.body;
                    if (incentiveDetail?.vehicle_lease_data !== null) {
                        const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveDetail }));

                        if (tempIncentiveLeaseDetailDataState.vehicle_lease_data !== undefined) {
                            const tempLeaseData = JSON.parse(JSON.stringify({ ...incentiveDetail }));
                            tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = tempLeaseData?.vehicle_lease_data?.lease_data?.[0];


                            setIncentiveLeaseDetailData(tempIncentiveLeaseDetailDataState);

                            // console.log(tempIncentiveLeaseDetailDataState);

                            if (incentiveDetail?.vehicle_lease_data !== null) {
                                let filterValue = { ...filter };
                                filterValue['annual_mileage'] = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.annual_mileage;
                                filterValue['lease_term'] = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.lease_term;
                                setFilter(filterValue);
                            }
                        }
                    }

                }
            }


            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: true,
                leaseData: incentiveDetail,
                filter: filter
            }));

        } catch (error) {
            console.error("error", error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    const handleHideShowClick = (rowId) => {
        setExpandedRows((prev) => ({
            [rowId]: !prev[rowId],
        }));
    };

    return (
        <Fragment>
            {/* {displaySkeletonView && */}
                <PageLoaderComponent loading={displaySkeletonView} />
                {/* } */}
            {isLogin && isEmpty(stateIncentiveDetail?.error?.msg) ? (
                <div className={displaySkeletonView === true ? "detail-main" : "detail-main main-animation"}>
                    <section className="detaile-top-sticky">
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex align-items-center justify-content-between flex-wrap bg-detail-top py-3 px-lg-5 px-3">
                                    <div className="">
                                        <h3 className="font-wx font-largeX font-white">{model_year} {make} {model}</h3>
                                        {incentiveBuyDetailData?.trim_list &&
                                            <Form.Select className="font-small font-wxl font-color"
                                                aria-label="Default select example"
                                                disabled={isLoading}
                                                value={trim}
                                                onChange={(e) => onUpdate("trim", e.target.value)}
                                            >
                                                {incentiveBuyDetailData?.trim_list?.map((trim, keyIndex) => {
                                                    return (<option className="font-small font-wxl font-color" key={'trim_option' + keyIndex} value={trim}>{trim}</option>);
                                                })
                                                }
                                            </Form.Select>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <ModelDetailSection
                        incentiveBuyDetailData={incentiveBuyDetailData}
                        chargingDetail={incentiveBuyDetailData?.charging_data}
                        setExpandedMoreFeature={setExpandedMoreFeature}
                        expandedMoreFeature={expandedMoreFeature}
                        expandedRows={expandedRows}
                        isLoading={isLoading}
                    />

                    <TabSection
                        incentiveBuyDetailData={incentiveBuyDetailData}
                        incentiveLeaseDetailData={incentiveLeaseDetailData}
                        filter={filter}
                        setFilter={setFilter}
                        incentiveAmount={incentiveAmount}
                        setIncentiveAmount={setIncentiveAmount}
                        handleHideShowClick={handleHideShowClick}
                        expandedRows={expandedRows}
                        tooltipText={incentiveBuyDetailData?.tooltips}
                        onReset={onReset}
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                        setExpandedRows={setExpandedRows}
                        settotalSavingAmount={settotalSavingAmount}
                        setSpecialIncentives={setSpecialIncentives}
                        specialIncentives={specialIncentives}
                        totalSavingAmount={totalSavingAmount}
                        incentiveCheckState={incentiveCheckState}
                        updateIncentiveState={updateIncentiveState}
                        isLoading={isLoading}
                        displayAGI={displayAGI}
                    />

                    <ChargingSection
                        chargingDetail={incentiveBuyDetailData?.charging_data}
                        chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                        tooltipText={incentiveBuyDetailData?.tooltips}
                        isLoading={isLoading}
                    />
                    {/* <section className="mx-lg-5 mx-3 "> */}
                    {!isEmpty(incentiveBuyDetailData?.last_updated_date) &&
                        <Row className="pt-5 px-lg-5 px-3 pb-2 last-updated-date">
                            <Col>
                                <label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(incentiveBuyDetailData?.last_updated_date)}</label>
                            </Col>
                        </Row>
                    }
                    {/* </section> */}
                </div>
            ) :
                <main className="blank-page main-animation"></main>
            }
        </Fragment>
    )

}

export default withRouter(EVIncentiveDetail);


