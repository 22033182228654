import { Fragment } from "react";
import { withRouter } from "react-router-dom";

const RedClose = ({
    height,
    width
}) => {
    return (
        <Fragment>
            <svg className="closeDanger" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7962 2.79636L14.7962 2.79634C14.9008 2.69176 14.9838 2.5676 15.0404 2.43096C15.097 2.29431 15.1261 2.14786 15.1261 1.99996C15.1261 1.85205 15.097 1.7056 15.0404 1.56895C14.9838 1.43231 14.9008 1.30815 14.7962 1.20357C14.6916 1.09898 14.5675 1.01602 14.4308 0.959424C14.2942 0.902824 14.1477 0.873693 13.9998 0.873693C13.8519 0.873693 13.7055 0.902824 13.5688 0.959424C13.4322 1.01602 13.308 1.09898 13.2034 1.20357L13.2034 1.20358L7.99983 6.40915L2.79633 1.20368C2.79633 1.20367 2.79632 1.20367 2.79632 1.20366C2.69182 1.0989 2.56768 1.01579 2.43101 0.959076C2.29433 0.902361 2.14781 0.873169 1.99983 0.873169C1.85186 0.873169 1.70534 0.902361 1.56866 0.959076C1.43203 1.01577 1.30792 1.09885 1.20345 1.20357C1.09873 1.30805 1.01565 1.43215 0.958954 1.56878C0.902239 1.70546 0.873047 1.85198 0.873047 1.99996C0.873047 2.14793 0.902239 2.29445 0.958954 2.43113C1.01567 2.5678 1.09878 2.69194 1.20354 2.79644C1.20355 2.79644 1.20355 2.79645 1.20356 2.79646L6.40902 7.99996L1.20346 13.2036L1.20345 13.2036C1.09886 13.3082 1.0159 13.4323 0.959302 13.569C0.902702 13.7056 0.87357 13.8521 0.87357 14C0.87357 14.1479 0.902702 14.2943 0.959302 14.431C1.0159 14.5676 1.09886 14.6918 1.20345 14.7963C1.30803 14.9009 1.43219 14.9839 1.56883 15.0405C1.70548 15.0971 1.85193 15.1262 1.99983 15.1262C2.14774 15.1262 2.29419 15.0971 2.43084 15.0405C2.56748 14.9839 2.69164 14.9009 2.79622 14.7963L2.79624 14.7963L7.99983 9.59077L13.2034 14.7963L13.2034 14.7963C13.308 14.9009 13.4322 14.9839 13.5688 15.0405C13.7055 15.0971 13.8519 15.1262 13.9998 15.1262C14.1477 15.1262 14.2942 15.0971 14.4308 15.0405C14.5675 14.9839 14.6916 14.9009 14.7962 14.7963C14.9008 14.6918 14.9838 14.5676 15.0404 14.431C15.097 14.2943 15.1261 14.1479 15.1261 14C15.1261 13.8521 15.097 13.7056 15.0404 13.569C14.9838 13.4323 14.9008 13.3082 14.7962 13.2036L14.7962 13.2036L9.59064 7.99996L14.7962 2.79636Z" fill="#891C1C" stroke="#891C1C" stroke-width="0.25" />
            </svg>
        </Fragment>
    );
}

export default withRouter(RedClose);