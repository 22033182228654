import { Fragment } from "react";
import { withRouter } from "react-router-dom";

const CheckGreen = ({
    height,
    width
}) => {
    return (
        <Fragment>
            <svg className="greencheck" width="16" height="13" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.9403 0.94095C14.222 0.672728 14.597 0.524444 14.9859 0.527387C15.3749 0.53033 15.7476 0.684271 16.0252 0.956725C16.3029 1.22918 16.4638 1.59884 16.4741 1.9877C16.4844 2.37656 16.3432 2.7542 16.0803 3.04095L8.10032 13.021C7.9631 13.1687 7.79748 13.2874 7.61338 13.3697C7.42927 13.452 7.23046 13.4964 7.02882 13.5001C6.82718 13.5038 6.62685 13.4669 6.43983 13.3914C6.2528 13.316 6.08291 13.2036 5.94031 13.0609L0.648315 7.76895C0.500942 7.63163 0.382737 7.46602 0.300753 7.28203C0.218769 7.09803 0.174685 6.8994 0.171132 6.698C0.167578 6.49659 0.204628 6.29653 0.28007 6.10975C0.355512 5.92298 0.467801 5.75331 0.610239 5.61087C0.752676 5.46844 0.922344 5.35615 1.10912 5.2807C1.2959 5.20526 1.49595 5.16821 1.69736 5.17177C1.89877 5.17532 2.09739 5.2194 2.28139 5.30139C2.46539 5.38337 2.63099 5.50158 2.76831 5.64895L6.95632 9.83495L13.9023 0.98495C13.9148 0.969552 13.9282 0.954862 13.9423 0.94095H13.9403Z" fill="#2E653A" />
            </svg>
        </Fragment>
    );
}

export default withRouter(CheckGreen);