import React, { Fragment } from "react";
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import msgcheck from '../../assets/img/msgcheck.png';
import msgclose from '../../assets/img/msgclose.png';
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field } from 'formik';
import { useMask } from '@react-input/mask';
import { isEmpty, isValidateZipcode, handleDragStart, handleContextMenu } from "../../helper/project_helper";

const UserProfile = ({
    userData,
    updateProfile,
    stateUserDetail,
    phoneNumber,
    setPhoneNumber,
    zipCode,
    onChangeZip,
    invalidZipCode,
    setUpdateProfileSuccess,
    updateProfileSuccess,
    loading,
    setZipError,
    zipError
}) => {

    const inputRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });
    const inputRefZip = useMask({ mask: '_____', replacement: { _: /\d/ } });


    const validationSchema = Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
    });
    // const onblureZip = (zip) => {
    //     if (zipCode.length < 5 || zipCode.length > 5) {
    //         setZipError(true)
    //     } else {
    //         setZipError(false)
    //     }
    // }




    return (
        <Fragment>
            <div className="setting-border p-3 mb-5">
                <h3 className="font-w font-px font-color mb-4">My Profile</h3>
                <div className="d-flex">
                    <div className="pe-lg-5 pe-3 border-right">
                        <div className="user-profile-letter d-flex align-items-center justify-content-center">
                            <p className="font-largeX font-w4">{userData?.first_name?.substring(0, 1)?.toUpperCase()}</p>
                        </div>
                    </div>
                    <div className="ps-lg-5 ps-3">
                        <p className="font-w font-large font-color mb-2">{userData?.first_name} {userData?.last_name}</p>
                        <p className="font-color font-w4 font-midium">{userData?.email}</p>
                    </div>
                </div>
            </div>
            <div className="setting-border p-3 mb-5">
                <Formik
                    initialValues={{ first_name: userData?.first_name, last_name: userData?.last_name }}
                    validationSchema={validationSchema}  // Pass the validation schema
                    onSubmit={(values) => {
                        if (zipCode.length < 5 || zipCode.length > 5) {
                            setZipError(true)
                        } else {

                            setZipError(false)
                            if (!isEmpty(phoneNumber)) {
                                values.phone_number = phoneNumber?.replace(/\D/g, '');
                            }
                            values.zip_code = zipCode
                            if (userData?.zip_code !== values.zip_code || userData?.first_name !== values.first_name || userData?.last_name !== values.last_name || values?.phone_number !== userData?.phone_number) {
                                updateProfile(values);
                            }
                        }
                    }}
                >
                    {({ handleSubmit, values, errors, touched, dirty }) => (
                        <Form className="mt-3" onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="6" controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="font-midium font-w font-color m-0">First Name</Form.Label>
                                    <Field
                                        type="text"
                                        className={errors.first_name && touched.first_name ? "error-border border-w form-control font-midium font-wxl font-color" : "border-w form-control font-midium font-wxl font-color"}
                                        name="first_name"
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="font-midium font-w font-color m-0">Last Name</Form.Label>
                                    <Field
                                        type="text"
                                        name="last_name"
                                        className={errors.last_name && touched.last_name ? "error-border border-w form-control font-midium font-wxl font-color" : "border-w form-control font-midium font-wxl font-color"}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationCustom02" className="mb-3">
                                    <Form.Label className="font-midium font-w font-color m-0">Phone Number</Form.Label>
                                    <input
                                        type="text"
                                        onChange={(x) => setPhoneNumber(x.target.value)}
                                        ref={inputRef}
                                        value={phoneNumber}
                                        className="border-w form-control font-midium font-wxl font-color"
                                        name="phone_number"
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationCustom02" className="mb-3">
                                    <Form.Label className="font-midium font-w font-color m-0">Zip Code</Form.Label>
                                    <input
                                        onChange={(x) => onChangeZip(x.target.value)}
                                        ref={inputRefZip}
                                        value={zipCode}
                                        // onBlur={onblureZip}
                                        type="text"
                                        disabled={loading}
                                        className={zipError === false || invalidZipCode !== false ? "zip-focus border-w form-control font-midium font-wxl font-color" : "zip-focus error-border border-w form-control font-midium font-wxl font-color"}
                                    />
                                    {invalidZipCode &&
                                        <span className="warning-msg" style={{ marginLeft: "1%"}}>{invalidZipCode}</span>}
                                </Form.Group>
                            </Row>

                            {/* <div className="text-start">
                                {updateProfileSuccess &&
                                    (<Container>
                                        <Row>
                                            <Col lg={{ span: 9, offset: 3 }} >
                                                <div className="msg-note py-2 d-flex align-items-center justify-content-between px-2 mb-4" style={{ background: "#dbeedf" }}>
                                                    <div className="d-flex align-items-center">

                                                        <img className="me-3" src={msgcheck} alt="" /><p className="font-small">{updateProfileSuccess}</p>
                                                    </div>
                                                    <img src={msgclose} className="cursor-pointer" alt="close msg" onClick={() => setUpdateProfileSuccess(false)} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>)
                                    }
                                    </div> */}
                            <Row>
                                <Col lg={9}>
                                    <div className="text-start">
                                        {updateProfileSuccess &&
                                            (<Container>
                                                <div className="msg-note py-2 d-flex align-items-center justify-content-between px-2 mb-4" style={{ background: "#000" }}>
                                                    <div className="d-flex align-items-center">

                                                        <img
                                                            onContextMenu={handleContextMenu}
                                                            onDragStart={handleDragStart}
                                                            className="me-3"
                                                            src={msgcheck} alt=""
                                                        /><p className="warning-msg font-white">{updateProfileSuccess}</p>
                                                    </div>
                                                    {/* <img src={msgclose}
                                                        onContextMenu={handleContextMenu}
                                                        onDragStart={handleDragStart} className="cursor-pointer"
                                                        alt="close msg" onClick={() => setUpdateProfileSuccess(false)} /> */}
                                                </div>
                                            </Container>)
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">
                                        <Button type="submit"
                                            disabled={((userData?.phone_number === phoneNumber?.replace(/\D/g, '')) && userData?.zip_code === zipCode && !dirty) || loading || zipError}
                                            className={((userData?.phone_number === phoneNumber?.replace(/\D/g, '') && userData?.zip_code === zipCode && !dirty) ? "in-active-update-btn " : "") + "btn-learn-more"}>Update</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
}

export default withRouter(UserProfile);