import React from "react";
import Layout from "../layout/index";

import { publicRoutes } from "./allRoutes";
import Basic404 from "../pages/Basic404";
import {
    Switch,
    Route
} from "react-router-dom";


const App = () => {
    // const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    //const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);

    return (
        <React.Fragment>
            <Layout>
                <Switch>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            component={route.component}
                            key={idx}
                            exact={true}
                        />
                    ))}
                    <Route path="*" component={Basic404} />
                </Switch>
            </Layout>
        </React.Fragment>
    );
}


export default App;