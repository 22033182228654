import {
  EV_INCENTIVE_DETAIL,
  SET_EV_INCENTIVE_DETAIL_LOADING,
  UPDATE_EV_INCENTIVE_DETAIL_DATA,
  UPDATE_INCENTIVE_DATA_DATA
} from "./actionTypes";


export const EVIncentiveDetail = () => {
  return {
    type: EV_INCENTIVE_DETAIL,
    payload: {},
  };
};

export const setEVIncentiveDetailLoading = (loading) => {
  return {
    type: SET_EV_INCENTIVE_DETAIL_LOADING,
    payload: { loading },
  };
};

export const updateEVIncentiveDetail = (data) => {
  return {
    type: UPDATE_EV_INCENTIVE_DETAIL_DATA,
    payload: { data },
  };
};
export const updateIncentiveData = (data) => {
  return {
    type: UPDATE_INCENTIVE_DATA_DATA,
    payload: data ,
  };
};
