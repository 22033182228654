import {
  INCENTIVE_SUMMARY,
  SET_INCENTIVE_SUMMARY_LOADING,
  UPDATE_INCENTIVE_SUMMARY_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
};

const IncentiveSummary = (state = initialState, action) => {

  switch (action.type) {
    case INCENTIVE_SUMMARY:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_INCENTIVE_SUMMARY_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_INCENTIVE_SUMMARY_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default IncentiveSummary;
