import { Fragment } from "react";
import { withRouter } from "react-router-dom";

const LoaderComponent = ({
    loading,
    className = ''
}) => {
    return (
        <Fragment>
            {loading && (
                <div className={className === '' ? "d-flex mt-5" : className} style={{ justifyContent: "center" }}>
                <span className="spinner spinner-slow"></span>
                <span className="loading-text">
                    Loading&nbsp;.&nbsp;.&nbsp;.
                </span></div>
            )}
        </Fragment>
    );
}

export default withRouter(LoaderComponent);