import React from "react";
import { Col, Form } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { useMask } from '@react-input/mask';
import { isEmpty } from "../../helper/project_helper";

const Desktop = ({
    filterList,
    onChangeFilter,
    value,
    onSearchClick,
    onResetClick,
    handleZipBlur,
    zipCodeError,
    blankFilterError,
    filterloading
}) => {

    const inputRefZip = useMask({ mask: '_____', replacement: { _: /\d/ } });
    
    return (
        <Col className="" lg={3}>
            <div className="p-3 filter-sicky" style={{ background: "#fff" }}>
                <div className="mb-2 mt-1">
                    <label className="mb-1 font-midium font-w text-detaile">Zip Code</label><span className="ms-1">*</span>
                    <Form.Control
                        className={"border-w height-set text-font zip-focus " + (zipCodeError && " error-border")}
                        placeholder=""
                        aria-label="Zip code"
                        aria-describedby="basic-addon1"
                        value={value?.zip_code}
                        ref={inputRefZip}
                        onChange={(x) => onChangeFilter("zip_code", x.target.value)}
                        disabled={filterloading}
                        // onBlur={handleZipBlur}
                    />
                    <span className="mt-5 warning-msg">
                        {zipCodeError ? "Enter a valid Zip code." : ""}{" "}
                    </span>
                </div>
                <div className="mb-2">
                    <label className="mb-1 font-midium font-w text-detaile">Make</label><span className="ms-1">*</span>
                    <Form.Select
                        className={"height-set text-font " + (blankFilterError?.make === true && "error-border")}
                        aria-label="Default select example"
                        value={value?.make}
                        disabled={filterloading}
                        onChange={(x) => onChangeFilter("make", x.target.value)}
                    >
                        <option value="">Select</option>
                        {filterList?.make && filterList?.make?.length > 0 && (
                            filterList?.make?.map((value, key) => {
                                return (
                                    <option key={key} value={value}>{value}</option>
                                );
                            })
                        )}
                    </Form.Select>
                </div>
                <div className="mb-2">
                    <label className="mb-1 font-midium font-w text-detaile">Model</label><span className="ms-1">*</span>
                    <Form.Select
                        className={"height-set text-font " + (blankFilterError?.model === true && "error-border")}
                        aria-label="Default select example"
                        value={value?.model}
                        disabled={filterloading}
                        onChange={(x) => onChangeFilter("model", x.target.value)}
                    >
                        <option value={""}>Select</option>
                        {filterList?.model && filterList?.model?.length > 0 && (
                            filterList?.model?.map((value, key) => {
                                return (
                                    <option key={key} value={value}>{value}</option>
                                );
                            })
                        )}
                    </Form.Select>
                </div>
                <div className="mb-2">
                    <label className="mb-1 font-midium font-w text-detaile">Trim</label><span className="ms-1">*</span>
                    <Form.Select
                        className={"height-set text-font " + (blankFilterError?.trim === true && "error-border")}
                        aria-label="Default select example"
                        value={value?.trim}
                        onChange={(x) => onChangeFilter("trim", x.target.value)}
                        disabled={filterList?.trim?.length === 0 || filterloading}
                    >
                        <option value={""}>Select</option>
                        {filterList?.trim && filterList?.trim?.length > 0 && (
                            filterList?.trim?.map((value, key) => {
                                return (
                                    <option key={key} value={value}>{value}</option>
                                );
                            })
                        )}
                    </Form.Select>
                </div>
                <div className="mb-2">
                    <label className="mb-1 font-midium font-w">Model Year</label><span className="ms-1">*</span>
                    <div className={"d-flex"}>
                        {filterList?.model_year && filterList?.model_year?.length > 0 && (
                            filterList?.model_year?.map((modelYearVal, key) => {
                                return (
                                    <Form.Check
                                        onChange={(x) => onChangeFilter("model_year", x.target.value)}
                                        className="radio-check w-25 font-wx"
                                        label={modelYearVal}
                                        value={modelYearVal}
                                        disabled={filterloading}
                                        checked={Number(value?.model_year) === Number(modelYearVal)}
                                    />
                                )
                            }))}
                    </div>
                </div>
                <div className="mb-2">
                    <label className="mb-1 font-midium font-w">Purchase Type</label><span className="ms-1">*</span>
                    <div className="d-flex">
                        <Form.Check
                            className="radio-check w-25 font-wx"
                            checked={value?.purchase_type === "Buy"}
                            label="Buy"
                            value="Buy"
                            disabled={filterloading}
                            onChange={(x) => onChangeFilter("purchase_type", x.target.value)}
                        />
                        <Form.Check
                            checked={value?.purchase_type === "Lease"}
                            className="radio-check font-wx"
                            value="Lease"
                            label="Lease"
                            disabled={filterloading}
                            onChange={(x) => onChangeFilter("purchase_type", x.target.value)}
                        />
                    </div>
                </div>
                {/* <div className="mt-3 pt-1  ">
                    <button
                        className="btn-learn-more text-center w-100"
                        onClick={onSearchClick}
                        disabled={isEmpty(value?.zip_code) || isEmpty(value?.make) || isEmpty(value?.model) || isEmpty(value?.model_year) || (filterList?.trim?.length > 0 && isEmpty(value?.trim) || filterloading || zipCodeError)}
                    >
                        Find
                    </button>
                </div> */}
                <div className="">
                    <button
                        className="text-dark font-midium font-wx link-button reset-link p-0"
                        onClick={onResetClick}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </Col>);
}

export default withRouter(Desktop);