import {
    USER_DETAILS,
    SET_USER_DETAILS_LOADING,
    UPDATE_USER_DETAILS_DATA,
    UPDATE_USER_TEMP_DATA
  } from "./actionTypes";
  
  
  export const UserDetails = () => {
    return {
      type: USER_DETAILS,
      payload: {},
    };
  };
  
  export const setUserDetailsLoading = (loading) => {
    return {
      type: SET_USER_DETAILS_LOADING,
      payload: { loading },
    };
  };
  
  export const updateUserDetails = (data) => {
    return {
      type: UPDATE_USER_DETAILS_DATA,
      payload: { data },
    };
  };
  export const updateTempUserDetails = (data) => {
    return {
      type: UPDATE_USER_TEMP_DATA,
      payload: { data },
    };
  };
  