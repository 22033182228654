import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent"
import { Row, Col, Container, Form, Button, CloseButton } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';
import { height } from "@fortawesome/free-solid-svg-icons/fa0";

const CompareLoader = () => {
    return (
        <Fragment>
            <div className="mb-4">
                <SkeletonComponent count={1} className="height-c-1" />
            </div>
            <div className="px-5">

                <section className="">
                    <Row>
                        <Col lg={4}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-2" />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-2" />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-2" />
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="mt-4">
                    <Row className="g-4">
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-c-3" />
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>
        </Fragment>
    );
}

export default withRouter(CompareLoader);