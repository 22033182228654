import { Row, Col, Form, Button, CloseButton } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from 'react';
import { DateFormatterExpirationDate, isEmpty } from "../helper/project_helper";
export const IncentiveDetailPopup = ({
    specialIncentivesKey,
    handleHideShowClick,
    IncentiveObject
}) => {


    return (
        <section className='banner-detail-popup'>
            <Row>
                <Col lg={12}>
                    <div className='popup-close pt-1'>

                    </div>
                    <div className='text-end close-popup'>
                        <svg className='me-1 mb-1' onClick={() => handleHideShowClick(specialIncentivesKey)} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className='px-3 pt-3 pb-2'>
                        <div className='justify-content-between flex-wrap pb-3 d-flex'>
                            <h5 className='font-large font-color font-px font-w'>{IncentiveObject?.incentive_name}</h5>
                            <h5 className='font-large font-wx mb-0'>{IncentiveObject?.incentive_amount}</h5>
                        </div>
                        <div className='pb-3'>
                            <p className='me-2 font-midium font-w'>Provider: </p>
                            <p className='font-midium font-w4'>{IncentiveObject?.provider_name}</p>
                        </div>
                        <div className='pb-3'>
                            <p className='me-2 font-midium font-w'>Type: </p>
                            <p className='font-midium font-w4'>{IncentiveObject?.incentive_type}</p>
                        </div>
                        {!isEmpty(IncentiveObject?.end_date) &&
                            <div className='pb-3'>
                                <p className='me-2 font-midium font-w mb-1'>Expiration Date: </p>
                                <p className='font-midium font-w4'>{DateFormatterExpirationDate(IncentiveObject?.end_date)}</p>
                            </div>
                        }
                        {!isEmpty(IncentiveObject?.eligibility_requirement_text) &&
                            <div className='pb-3'>
                                <p className='me-2 font-midium font-w mb-1'>Eligibility Requirements: </p>
                                <p className='font-midium font-w4'>{IncentiveObject?.eligibility_requirement_text}</p>
                            </div>
                        }
                        <div className='pb-3'>
                            <p className='me-2 font-midium font-w'>Combinable? </p>
                            <p className='font-midium font-w4'>{IncentiveObject?.is_combinable == true ? "Yes" : "No"}</p>
                        </div>

                        {IncentiveObject?.exclusion_text != null &&
                            <Fragment>
                                <div className='pb-3'>
                                    <p className='me-2 font-midium font-w mb-1'>Exclusions: </p>
                                    <p className='font-midium font-w4'>{IncentiveObject?.exclusion_text}</p>
                                </div>
                            </Fragment>
                        }
                        {IncentiveObject?.redemption_text != null &&
                            <Fragment>
                                <div className={(!isEmpty(IncentiveObject?.redemption_link) || !isEmpty(IncentiveObject?.incentive_link)) && 'pb-4'}>
                                    <p className='me-2 font-midium font-w mb-1'>How to Claim? </p>
                                    <p className='font-midium font-w4'>{IncentiveObject?.redemption_text}</p>
                                </div>
                            </Fragment>
                        }

                        {!isEmpty(IncentiveObject?.redemption_link) &&
                            <div className="pb-3 pt-1">
                                <a className="btn-learn-more text-center" target="_blank" href={IncentiveObject?.redemption_link} rel="noopener noreferrer" >Learn more
                                    <FontAwesomeIcon icon={faUpRightFromSquare}
                                        className="ms-3"
                                    />
                                </a>
                            </div>
                        }
                        {isEmpty(IncentiveObject?.redemption_link) && !isEmpty(IncentiveObject?.incentive_link) &&
                            <div className="pb-3 pt-1">
                                <a className="btn-learn-more text-center" target="_blank" href={IncentiveObject?.incentive_link} rel="noopener noreferrer" >Learn more
                                    <FontAwesomeIcon icon={faUpRightFromSquare}
                                        className="ms-3"
                                    />
                                </a>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </section>
    )
}