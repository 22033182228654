import {
  INCENTIVE_SEARCH_BY_ALL,
  SET_INCENTIVE_SEARCH_BY_ALL_LOADING,
  UPDATE_INCENTIVE_SEARCH_BY_ALL_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
};

const IncentiveFinderByAll = (state = initialState, action) => {

  switch (action.type) {
    case INCENTIVE_SEARCH_BY_ALL:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_INCENTIVE_SEARCH_BY_ALL_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_INCENTIVE_SEARCH_BY_ALL_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default IncentiveFinderByAll;
