import {
    POPUP_DATA,
    UPDATE_AUTH_POPUP_STATE
  } from "./actionTypes";
  import { defaultState } from './reducer'
  
  
  export const statePopUpData = () => {
    return {
      type: POPUP_DATA,
      payload: {},
    };
  };
  
/*


let bb = {abc:"",cc:"" }
let bbDDD = {abcdd:"",ccd:"" }
let bb_3 = {abc:"" }

lo = { ...bb, ...bbDDD ,   };


1 . updateAuthPoupState( {   ...defaultPropsState, Foloe:true }, callbackMethod = null )
2 . clear callbackMethod method 


** */

  export const updateAuthPoupState = (props, callbackMethod = null ) => {
    return {
      type: UPDATE_AUTH_POPUP_STATE,
      payload: { props, callbackMethod },
    };
  };

  