import { Fragment } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { withRouter } from "react-router-dom";

const SkeletonComponent = ({
    count,
    className
}) => {
    return (
        <Fragment>
            <Skeleton count={count} className={className} />
        </Fragment>
    );
}

export default withRouter(SkeletonComponent);