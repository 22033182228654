import {
  INCENTIVE_DETAIL_SEARCH,
  SET_INCENTIVE_DETAIL_SEARCH_LOADING,
  UPDATE_INCENTIVE_DETAIL_SEARCH_DATA,
} from "./actionTypes";


export const IncentiveDetailSearch = () => {
  return {
    type: INCENTIVE_DETAIL_SEARCH,
    payload: {},
  };
};

export const setIncentiveDetailSearchLoading = (loading) => {
  return {
    type: SET_INCENTIVE_DETAIL_SEARCH_LOADING,
    payload: { loading },
  };
};

export const updateIncentiveDetailSearchData = (data) => {
  return {
    type: UPDATE_INCENTIVE_DETAIL_SEARCH_DATA,
    payload: { data },
  };
};
