import {
  INCENTIVE_DETAIL_SEARCH,
  SET_INCENTIVE_DETAIL_SEARCH_LOADING,
  UPDATE_INCENTIVE_DETAIL_SEARCH_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
};

const IncentiveDetailSearch = (state = initialState, action) => {

  switch (action.type) {
    case INCENTIVE_DETAIL_SEARCH:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_INCENTIVE_DETAIL_SEARCH_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_INCENTIVE_DETAIL_SEARCH_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default IncentiveDetailSearch;
