import {
  POPUP_DATA,
  UPDATE_AUTH_POPUP_STATE
} from "./actionTypes";
//import * as helper from "../../helper/sessionStorage";

export const defaultState = {
  AskSignIn: false,
  SignIn: false,
  SignUp: false,
  ForgetPassword: false,
  ForgotPasswordConfirm: false,
  VerificationCode: false,
  CloseALL: false,
  signInSuccessCallback: false
};

const initialState = {
  ...defaultState

};

const AuthPopupState = (state = initialState, action) => {

  switch (action.type) {
    case POPUP_DATA:
      state = {
        ...state,
      };
      break;
    case UPDATE_AUTH_POPUP_STATE: {
      const { signInSuccessCallback, ...rest } = action.payload.props;
      const newState = { ...defaultState, ...rest };

      if (action.payload.callbackMethod !== null) {
        newState.signInSuccessCallback = action.payload.callbackMethod;
      }else{
        newState.signInSuccessCallback = state.signInSuccessCallback;
      }

      state = { ...state, ...newState };
    };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AuthPopupState;
