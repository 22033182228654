import { Row, Col, Nav, Tab } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import LeaseIncentiveSection from "./LeaseIncentiveSection";
import BuyIncentiveSection from "./BuyIncentiveSection";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { isEmpty } from '../../helper/project_helper';


const TabSection = ({
    incentiveBuyDetailData,
    incentiveLeaseDetailData,
    filter,
    setFilter,
    incentiveAmount,
    setIncentiveAmount,
    handleHideShowClick,
    expandedRows,
    tooltipText,
    onReset,
    currentTab,
    setCurrentTab,
    setExpandedRows,
    settotalSavingAmount,
    setSpecialIncentives,
    specialIncentives,
    totalSavingAmount,
    incentiveCheckState,
    updateIncentiveState,
    isLoading,
    displayAGI
}) => {

    const [leaseDisabled, setLeaseDisabled] = useState(false);

    useEffect(() => {

        if (((incentiveLeaseDetailData?.vehicle_lease_data?.specialIncentives === null || incentiveLeaseDetailData?.vehicle_lease_data?.specialIncentives?.length === 0) 
            && (isEmpty(String(incentiveLeaseDetailData?.vehicle_lease_data?.lease_data?.lease_term))) && (incentiveLeaseDetailData?.vehicle_lease_data?.total_potential_savings === null || incentiveLeaseDetailData?.vehicle_lease_data?.total_potential_savings?.length === 0))) {
            setLeaseDisabled(true);
        }else if(incentiveLeaseDetailData?.vehicle_lease_data === undefined){
            setLeaseDisabled(true);
        }else{
            setLeaseDisabled(false);
        }
    }, [incentiveLeaseDetailData]);

    return (
        <section className="mx-lg-5 mx-3 mt-2">
            <Tab.Container id="left-tabs-example" defaultActiveKey={currentTab}>
                <Col lg={12}>
                    <Nav variant="pills" className="flex-column">
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <Nav.Item>
                                    <Nav.Link eventKey="Buy"
                                        onClick={() => {
                                            setCurrentTab('Buy');
                                            setExpandedRows({});
                                        }}
                                    >Buy</Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <Nav.Item>
                                    <Nav.Link
                                        className={leaseDisabled ? "blur-text" : ""} 
                                        disabled={leaseDisabled}
                                        eventKey="Lease"
                                        onClick={() => {
                                            if(!leaseDisabled)
                                            {
                                                setCurrentTab('Lease');
                                                setExpandedRows({});
                                            }
                                        }}
                                    >Lease</Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Row>
                    </Nav>
                </Col>
                <Col lg={12}>
                    <Tab.Content>
                        <BuyIncentiveSection
                            buyDetail={incentiveBuyDetailData?.buy}
                            filter={filter}
                            tax_filing_status={incentiveBuyDetailData?.tax_filing_status}
                            household_income={incentiveBuyDetailData?.household_income}
                            setUpdatedFilter={setFilter}
                            incentiveAmount={incentiveAmount}
                            setIncentiveAmount={setIncentiveAmount}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            tooltipText={tooltipText}
                            onReset={onReset}
                            chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                            settotalSavingAmount={settotalSavingAmount}
                            setSpecialIncentives={setSpecialIncentives}
                            specialIncentives={specialIncentives}
                            totalSavingAmount={totalSavingAmount}
                            incentiveCheckState={incentiveCheckState}
                            updateIncentiveState={updateIncentiveState}
                            isLoading={isLoading}
                            displayAGI={displayAGI}
                        />
                        <LeaseIncentiveSection
                            leaseDetail={incentiveLeaseDetailData}
                            filter={filter}
                            setUpdatedFilter={setFilter}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            // chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                            chargingIncentives={incentiveLeaseDetailData?.vehicle_lease_data?.chargingIncentives}
                            isLoading={isLoading}
                        />
                    </Tab.Content>
                </Col>
            </Tab.Container>
        </section>
    )
}

export default withRouter(TabSection);