import {
  EV_INCENTIVE_DETAIL,
  SET_EV_INCENTIVE_DETAIL_LOADING,
  UPDATE_EV_INCENTIVE_DETAIL_DATA,
  UPDATE_INCENTIVE_DATA_DATA
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
  incentiveData: {

  },
};

const EVIncentiveFinder = (state = initialState, action) => {

  switch (action.type) {
    case EV_INCENTIVE_DETAIL:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_EV_INCENTIVE_DETAIL_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_EV_INCENTIVE_DETAIL_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    case UPDATE_INCENTIVE_DATA_DATA:{
      state = {
        ...state,
        incentiveData: action.payload
      };
      }break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EVIncentiveFinder;
