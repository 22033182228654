import {
  COMPARE_VEHICLE,
  SET_COMPARE_VEHICLE_LOADING,
  UPDATE_COMPARE_VEHICLE_DATA,
} from "./actionTypes";


export const compareVehiclesState = () => {
  return {
    type: COMPARE_VEHICLE,
    payload: {},
  };
};

export const setCompareVehiclesLoading = (loading) => {
  return {
    type: SET_COMPARE_VEHICLE_LOADING,
    payload: { loading },
  };
};

export const updateCompareVehicles = (data) => {
  return {
    type: UPDATE_COMPARE_VEHICLE_DATA,
    payload: { data },
  };
};
