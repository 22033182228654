import {
  INCENTIVE_DETAIL_SEARCH_FILTERS,
  SET_INCENTIVE_DETAIL_SEARCH_FILTERS_LOADING,
  UPDATE_INCENTIVE_DETAIL_SEARCH_FILTERS_DATA,
} from "./actionTypes";


export const IncentiveDetailSearchFilters = () => {
  return {
    type: INCENTIVE_DETAIL_SEARCH_FILTERS,
    payload: {},
  };
};

export const setIncentiveDetailSearchFiltersLoading = (loading) => {
  return {
    type: SET_INCENTIVE_DETAIL_SEARCH_FILTERS_LOADING,
    payload: { loading },
  };
};

export const updateIncentiveDetailSearchFiltersData = (data) => {
  return {
    type: UPDATE_INCENTIVE_DETAIL_SEARCH_FILTERS_DATA,
    payload: { data },
  };
};
