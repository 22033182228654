import { createStore, applyMiddleware, compose } from "redux";
//import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
//import rootSaga from "./sagas";

//const sagaMiddleware = createSagaMiddleware();
//const middlewares = [sagaMiddleware];

//redux dispach logs console
// if (process.env.NODE_ENV === `development`) {
//   const { createLogger } = require(`redux-logger`);//logger
//   const stateInfo = require("./stateDebug");

//   const stateList = [ "DeductionsFilter" ];
//   const actionsTypes = stateInfo.stateNameToActions(stateInfo,stateList);

//   const logger = createLogger({
//     predicate: (getState, action) => {
//       return actionsTypes.includes(action.type);
//     },
//     stateTransformer: (state) => {
//       let newState = {};
//       for (const [key, value] of Object.entries(state)) {
//         if(stateList.includes(key)){
//           if( stateList.includes.length === 1 ){
//             newState = value;
//           }else{
//             newState[key] = value;
//           }
//         }
//       }
//       return newState;
//     }
//   });

//   middlewares.push(logger);
// }


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const composeEnhancers = (process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS==="true")?(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose):compose;
//const composeEnhancers = compose;

var createdStore = false;

export function configureStore(initialState) {

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      //applyMiddleware(...middlewares)
      applyMiddleware()
    ),
  );
  createdStore = store;
  //sagaMiddleware.run(rootSaga);
  return store;
}

export function getStore() {
  return createdStore;
}
