import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import UserProfile from "./UserProfile";
import { useAuth } from "../../components/hooks/useAuth";
import { setUserDetailsLoading, updateUserDetails, updateAuthPoupState } from "../../store/action";
import { useSelector, useDispatch } from "react-redux";
import { defaultPopUpState, isEmpty, formatPhoneValue, isValidateZipcode } from '../../helper/project_helper';
import * as apiHelper from "../../helper/api_helper";
import * as helper from "../../helper/sessionStorage";
import { clearLoginUserData } from "../../helper/sessionStorage";
import { withRouter } from "react-router-dom";

const Profile = ({
    history
}) => {

    const [first, setFirst] = useState(true);
    const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
    const [updateProfileError, setUpdateProfileError] = useState(false);
    const [isLogin, userData] = useAuth();
    const dispatch = useDispatch();
    const [invalidZipCode, setInvalidZipCode] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(formatPhoneValue(userData?.phone_number));
    const [zipCode, setZipCode] = useState(userData?.zip_code);
    const [zipError, setZipError] = useState(false);

    const { stateUserDetail, statePopUpData } = useSelector((state) => ({
        stateUserDetail: state.UserDetails,
        statePopUpData: state.AuthPopupState,
    }));

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData]);

    const onLogin = (x) => {

    }

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    if (first === true) {
        if (userData === false || userData === undefined) {
            askLogin();
        }
        setFirst(false);
    }

    async function getProfile(params = {}) {

        dispatch(setUserDetailsLoading(true));
        try {
            const response = await apiHelper.postGetProfile(params);
            if (response?.data?.status === 1) {
                console.log(response?.data?.body);

                document.title = response?.data?.body?.page_title;
                document.querySelector('meta[name="description"]').setAttribute("content", response?.data?.body?.page_meta_description);

                dispatch(setUserDetailsLoading(false));
            } else if (response?.data?.status === 2) {
                dispatch(setUserDetailsLoading(false));
                askLogin();
            } else {
                dispatch(setUserDetailsLoading(false));
            }
        } catch (error) {
            console.error(error);
            dispatch(setUserDetailsLoading(false));
        }
    }

    useEffect(() => {
        if (isLogin) {
            getProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    const goBack = () => {
        history.goBack();  // Navigates to the previous page
        setTimeout(() => {
            window.scrollTo(0, 0); // Add a small delay
        }, 100);
    }

    async function updateProfile(params = {}) {
        dispatch(setUserDetailsLoading(true));
        try {
            const response = await apiHelper.postUpdateProfile(params);

            if (response?.data?.status === 1) {
                setUpdateProfileError(false);
                dispatch(setUserDetailsLoading(false));
                setUpdateProfileSuccess(response?.data?.msg);
                const uData = response?.data.body;
                const temUserData = { ...userData }
                temUserData.zip_code = uData?.zip_code;
                temUserData.first_name = uData?.first_name;
                temUserData.last_name = uData?.last_name;
                temUserData.phone_number = uData?.phone_number;
                helper.setLoginUserData(temUserData);
                dispatch(updateUserDetails({
                    loading: false,
                    status: true,
                    data: temUserData,
                }));
                setInvalidZipCode(false);
                setTimeout(() => {
                    goBack();
                }, 2000)
            } else if (response?.data?.status === 0) {
                dispatch(setUserDetailsLoading(false));
                setUpdateProfileError(response?.data?.msg);
                setInvalidZipCode("Enter a valid Zip code.");
            } else if (response?.data?.status === 2) {
                // setUpdateProfileError("Something went wrong please try again after sometime!");
                setInvalidZipCode(false);
                askLogin();
            } else if (response?.data?.status === 3) {
                dispatch(setUserDetailsLoading(false));
                setInvalidZipCode("Enter a valid Zip code.");
            } else {
                setInvalidZipCode(false);
                dispatch(setUserDetailsLoading(false));
                setUpdateProfileError("Something went wrong please try again after sometime!");
            }
        } catch (error) {
            console.error(error);
            dispatch(setUserDetailsLoading(false));
            setUpdateProfileError("Something went wrong please try again after sometime!")
        }
    }

    // async function deleteAccount(params = {}) {
    //     dispatch(setUserDetailsLoading(true));
    //     try {
    //         const response = await apiHelper.postDeleteAccount(params);
    //         if (response?.data?.status === 1) {
    //             clearLoginUserData();
    //             dispatch(updateUserDetails(false));
    //             history.push('/', true);
    //         } else if (response?.data?.status === 0) {
    //             dispatch(setUserDetailsLoading(false));
    //         } else if (response?.data?.status === 2) {
    //             dispatch(setUserDetailsLoading(false));
    //             askLogin();
    //         } else {
    //             dispatch(setUserDetailsLoading(false));
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         dispatch(setUserDetailsLoading(false));
    //         setUpdateProfileError("Something went wrong please try again after sometime!")
    //     }
    // }

    const onChangeZip = async (zip) => {
        setZipCode(zip);
        if (zip.length !== 5) {

            setZipError(true);
            setInvalidZipCode("Enter a valid Zip code.");
        }
        else {
            dispatch(setUserDetailsLoading(true));
            const validZipCode = await isValidateZipcode(zip);
            dispatch(setUserDetailsLoading(false));
            if (validZipCode === false) {
                setZipError(true);
                setInvalidZipCode("Enter a valid Zip code.");
            } else {
                setZipError(false);
                setInvalidZipCode(false);
            }
            setTimeout(() => {

                const input = document.querySelector('.zip-focus');

                // Check if the element exists and has the class
                if (input && input.classList.contains('zip-focus')) {
                    input.focus(); // Give focus if the class is present
                }
            }, 100);
        }
    }

    return (
        <Fragment>
            {isEmpty(stateUserDetail?.error?.msg) &&
                <Fragment>
                    <section className="banner-setting-layout height">
                        <div className="mb-3 py-3" style={{ background: "#1f4427" }}>
                            <Container>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h1 className="m-0 font-wx font-largeX font-white">Account Management</h1>
                                </div>
                            </Container>
                        </div>
                        <Container>
                            <div className='popup-close-account text-end pe-2' style={{ cursor: "pointer" }} onClick={goBack}>
                                <svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                            </div>
                            <div className="pb-4">
                                <Row className="g-4">
                                    <Col lg={12}>
                                        <UserProfile
                                            userData={userData}
                                            updateProfile={updateProfile}
                                            stateUserDetail={stateUserDetail}
                                            setPhoneNumber={setPhoneNumber}
                                            phoneNumber={phoneNumber}
                                            setZipCode={setZipCode}
                                            zipCode={zipCode}
                                            invalidZipCode={invalidZipCode}
                                            updateProfileSuccess={updateProfileSuccess}
                                            setUpdateProfileSuccess={setUpdateProfileSuccess}
                                            loading={stateUserDetail?.loading}
                                            onChangeZip={onChangeZip}
                                            zipError={zipError}
                                            setZipError={setZipError}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>
                </Fragment>}
        </Fragment>
    )
}

export default withRouter(Profile);