import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation, Link, useHistory, useParams } from 'react-router-dom';



export const useMyHistory =(scrollTimeout=100)=>{
    const history = useHistory();
    const { pathname } = useLocation();
    //const ref = useRef()

    const push=(path, scrollToTop=null, state=null )=>{
        if ( history.push_org != undefined ){
            // console.log("found history.push_org")

            if( state === null ){
                history.push_org(path);
            }else{
                history.push_org(path, state);
            }
            
            if( scrollToTop === true ){
                // console.log("call scroll to top")
                setTimeout(() => {
                    window.scrollTo(0, 0); // Add a small delay
                }, scrollTimeout); // 100ms delay to ensure the page is rendered
            }
        }else{
            // console.log("Not found history.push_org")
        }
    }

    if ( history.push_org === undefined ){
        //ref.current = history.push;
        history.push_org = history.push;

        history.push = push;
    }

	
    return history;
}

// export const useMyHistoryProps =( history ,scrollTimeout=100)=>{

//     const push=(path, scrollToTop=null, state=null )=>{
//         if ( history.push_org != undefined ){
//             console.log("found history.push_org prop")

//             if( state === null ){
//                 history.push_org(path);
//             }else{
//                 history.push_org(path, state);
//             }
            
//             if( scrollToTop === true ){
//                 console.log("call scroll to top")
//                 setTimeout(() => {
//                     window.scrollTo(0, 0); // Add a small delay
//                 }, scrollTimeout); // 100ms delay to ensure the page is rendered
//             }
//         }else{
//             console.log("Not found history.push_org")
//         }
//     }

//     if ( history.push_org === undefined ){
//         //ref.current = history.push;
//         history.push_org = history.push;

//         history.push = push;
//     }

//     return history;
// }

