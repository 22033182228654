import React, { Fragment, useState, useEffect } from "react";
import BuyIncentiveSummary from "./BuyIncentiveSummary";
import LeaseIncentivesSummary from "./LeaseIncentivesSummary";
import { withRouter, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as apiHelper from "../../helper/api_helper";
import { ArrayAsc, defaultPopUpState, isEmpty } from "../../helper/project_helper";
import LoaderComponent from "../../components/LoaderComponent";
import {
    setIncentiveSummaryLoading,
    updateIncentiveSummary,
    setUserDetailsLoading,
    updateUserDetails,
    updateAuthPoupState
} from "../../store/action";
import * as helper from "../../helper/sessionStorage";
import { useAuth } from "../../components/hooks/useAuth";

const useDefaultValueIncentiveSummary = () => {
    let { vehicle_id, purchase_type, vehicle_condition, selected_incentives, other_incentives, lease_term, annual_mileage, tax_filing_status, household_income } = useParams();
    return {
        vehicle_id: vehicle_id || '',
        purchase_type: purchase_type || "",
        vehicle_condition: vehicle_condition || "",
        selected_incentives: selected_incentives === "0" ? "" : selected_incentives,
        other_incentives: other_incentives === "0" ? "" : other_incentives,
        lease_term: lease_term === "0" ? "" : lease_term,
        annual_mileage: annual_mileage === "0" ? "" : annual_mileage,
        tax_filing_status: tax_filing_status === "0" ? "" : tax_filing_status,
        household_income: household_income === "0" ? "" : household_income
    };
};

const IncentiveSummary = () => {

    const [filter, setFilter] = useState(useDefaultValueIncentiveSummary());
    const dispatch = useDispatch();
    const [isLogin, userData] = useAuth();
    const [first, setFirst] = useState(true);

    const { stateIncentiveSummary } = useSelector((state) => ({
        stateIncentiveSummary: state.IncentiveSummary,
    }));

    const onLogin = (x) => {

    }

    const askLogin = () => {
        helper.clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    if (first === true) {
        if (userData === false || userData === undefined) {
            askLogin();
        }
        setFirst(false);
    }

    async function getIncentiveSummaryData(params = {}) {

        if (stateIncentiveSummary.filter !== undefined && stateIncentiveSummary.filter !== false) {
            const lastFilter = ArrayAsc(stateIncentiveSummary.filter);
            const currentFilter = ArrayAsc(filter);
            if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                return false;
            }
        }

        dispatch(setIncentiveSummaryLoading(true))
        try {
            const response = await apiHelper.postIncentiveSummary(params);
            var resData = [];
            if (response.data.status === 1) {
                resData = response?.data?.body;
                dispatch(updateIncentiveSummary({
                    loading: false,
                    status: true,
                    data: resData,
                    filter: filter
                }));
            } else if (response.data.status === 2) {
                dispatch(setIncentiveSummaryLoading(false));
                askLogin();
            } else {
                dispatch(
                    updateIncentiveSummary({
                        loading: false,
                        status: false,
                        error: response.data,
                    })
                );
            }


        } catch (error) {
            console.error(error);
            dispatch(updateIncentiveSummary({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    useEffect(() => {

        if (stateIncentiveSummary.loading === false && stateIncentiveSummary.error === '' && isLogin) {
            getIncentiveSummaryData(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, isLogin]);

    return (
        <Fragment>
            <LoaderComponent loading={stateIncentiveSummary?.loading} />
            {stateIncentiveSummary?.loading === true && <div className="blank-page">
            </div>}
            {(!stateIncentiveSummary?.loading && isEmpty(stateIncentiveSummary?.error?.msg)) && (filter?.purchase_type === 'Buy' ?
                <BuyIncentiveSummary summaryData={stateIncentiveSummary?.data} /> :
                <LeaseIncentivesSummary summaryData={stateIncentiveSummary?.data} />)}
        </Fragment>
    )
}

export default withRouter(IncentiveSummary);