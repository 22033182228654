import {
  EV_INCENTIVE_FINDER_FILTERS,
  SET_EV_INCENTIVE_FINDER_FILTERS_LOADING,
  UPDATE_EV_INCENTIVE_FINDER_FILTERS_DATA,
} from "./actionTypes";


export const EVIncentiveFinderFilters = () => {
  return {
    type: EV_INCENTIVE_FINDER_FILTERS,
    payload: {},
  };
};

export const setEVIncentiveFinderFiltersLoading = (loading) => {
  return {
    type: SET_EV_INCENTIVE_FINDER_FILTERS_LOADING,
    payload: { loading },
  };
};

export const updateEVIncentiveFinderFiltersData = (data) => {
  return {
    type: UPDATE_EV_INCENTIVE_FINDER_FILTERS_DATA,
    payload: { data },
  };
};
