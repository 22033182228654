// import React from "react";
// import { Redirect } from "react-router-dom";
import EVIncentiveFinder from "../pages/EVIncentiveFinder";
import EVIncentiveDetail from "../pages/EVIncentiveDetail";
import IncentiveDetailSearch from "../pages/IncentiveDetailSearch";
import CompareVehicles from "../pages/CompareVehicles";
import IncentiveSummary from "../pages/IncentiveSummary";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactUs from "../pages/ContactUs";
import Account from "../pages/Account";
import Basic404 from "../pages/Basic404";
import Unauthorized from "../pages/Unauthorized";
const authProtectedRoutes = []

const publicRoutes = [
    { path: "/404", component: Basic404 },
    { path: "/unauthorized", component: Unauthorized },
    { path: "/vehicledetail/:purchase_type/:vehicle_id/:make/:model/:model_year/:trim?/", component: EVIncentiveDetail },
    { path: "/incentivedetail/:purchase_type?/:vehicle_id?/:make?/:model?/:model_year?/:trim?", component: IncentiveDetailSearch },
    { path: "/comparevehicles/:compare_vehicles", component: CompareVehicles },
    // { path: "/evincentivefinder", component: EVIncentiveFinder },
    // { path: "/incentivesummary/:vehicle_id/:purchase_type/:vehicle_condition/:other_incentives?/:selected_incentives?/:lease_term?/:annual_mileage?/:tax_filing_status?/:household_income?", component: IncentiveSummary },
    { path: "/privacypolicy", component: PrivacyPolicy },
    { path: "/contact", component: ContactUs },
    { path: "/account", component: Account },
    {
        path: "/",
        exact: true,
        component: EVIncentiveFinder,
    },
    // {
    //     path: "/",
    //     exact: true,
    //     component: IncentiveFinderByVehicle,
    // },
];

export { authProtectedRoutes, publicRoutes };
