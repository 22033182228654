import {
  INCENTIVE_FINDER_BY_VEHICLE,
  SET_INCENTIVE_FINDER_BY_VEHICLE_LOADING,
  UPDATE_INCENTIVE_FINDER_BY_VEHICLE_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
};

const IncentiveFinderByVehicle = (state = initialState, action) => {

  switch (action.type) {
    case INCENTIVE_FINDER_BY_VEHICLE:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_INCENTIVE_FINDER_BY_VEHICLE_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_INCENTIVE_FINDER_BY_VEHICLE_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default IncentiveFinderByVehicle;
