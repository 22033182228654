import React, { useMemo, useEffect, Fragment, useState } from "react";
import * as apiHelper from "../helper/api_helper";
import { arraysAreEqual, isEmpty } from "../helper/project_helper";
import { useSelector, useDispatch } from "react-redux";
import {
  setEVIncentiveFinderFiltersLoading,
  updateEVIncentiveFinderFiltersData,
} from "../store/action";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import "@fontsource/dm-sans";

const defaultValueIncentiveFinderFilters = (default_model_year = "") => {
  return {
    make: "",
    model: "",
    model_year: default_model_year,
    trim: "",
    base_msrp: '',
    range: '',
    eligible: false,
    potential_savings: false,
    segments: ''
  };
};

const EVIncentiveFinderFiltersMobile = ({
  value,
  onChange,
  onSearch,
  reset = false,
  filterState,
  loading
}) => {
  const dispatch = useDispatch();
  //const [value, setValue ]= useState( defaultValueIncentiveFinderFilters() );
  const [selectedSegments, setSelectedSegments] = useState(value?.segments !== '' ? value?.segments.split(',') : []);
  const [selectedModelYear, setSelectedModelYear] = useState(value?.model_year !== '' ? value?.model_year.split(',') : []);

  const { stateEVIncentiveFinderFilters } = useSelector((state) => ({
    stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
  }));

  useEffect(() => {
    if (
      stateEVIncentiveFinderFilters.status === false &&
      stateEVIncentiveFinderFilters.error === ""
    ) {
      if (stateEVIncentiveFinderFilters.loading === false) {
        getIncentiveFinderFilters();
      }
    }
  });

  const filters = useMemo(() => {
    if (stateEVIncentiveFinderFilters.data !== false) {
      return stateEVIncentiveFinderFilters.data;
    }
    return null;
  }, [stateEVIncentiveFinderFilters]);

  var isLoading = useMemo(() => {
    return stateEVIncentiveFinderFilters.loading || false;
  }, [stateEVIncentiveFinderFilters]);

  const onUpdate = (name, value1) => {
    let value_ = { ...value };
    value_[name] = value1;

    if (name?.trim() === "make") {
      if (value1 !== '') {
        if (filters.model[value1].indexOf(value?.model) === -1) {
          value_.model = "";
        }
      }
    }

    if (name?.trim() === "model") {
      if (value1 !== '') {
        const key = findKeyByValue(filters?.model,value_?.model, ['all']); 
        if(!isEmpty(key))
        {
          if(key !== value?.make)
          {
            value_.make = key;
          }
        }
      }
    }

    onChange(value_);
  };

  const findKeyByValue = (obj, value, excludeKeys = []) => {
    return Object.keys(obj)
      .filter(key => !excludeKeys.includes(key))  // Exclude specified keys
      .find(key => obj[key].includes(value));  // Find the key that contains the value
  };

  useEffect(() => {
    if (!arraysAreEqual(selectedSegments, value.segments)) {
      onUpdate('segments', selectedSegments.join(","))
    }
  }, [selectedSegments]);

  const handleSegmentChange = async (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the value to the array if checked
      setSelectedSegments((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setSelectedSegments((prev) =>
        prev.filter((option) => option !== value)
      );
    }
  };

  const handleModelYearChange = async (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the value to the array if checked
      setSelectedModelYear((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setSelectedModelYear((prev) =>
        prev.filter((option) => option !== value)
      );
    }
  };

  useEffect(() => {
    if (!arraysAreEqual(selectedModelYear, value.model_year)) {      
      onUpdate('model_year', selectedModelYear.join(","))
    }
  }, [selectedModelYear]);

  const getIncentiveFinderFilters = async () => {
    dispatch(setEVIncentiveFinderFiltersLoading(true));
    try {
      const response = await apiHelper.postGetEVIncentiveFinderFilters();
      var filter_data = {};
      if (response.status === 200) {
        filter_data = response.data.body.data;
      }
      dispatch(
        updateEVIncentiveFinderFiltersData({
          loading: false,
          status: true,
          data: filter_data,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        updateEVIncentiveFinderFiltersData({
          loading: false,
          status: false,
          error: error,
        })
      );
    }
  };

  const onResetClick = () => {
    onChange(defaultValueIncentiveFinderFilters())
  };
  const onFindClick = () => {
    onSearch(filterState);
  }

  return (
    <Fragment>
      <Col className="" lg={12} style={{ background: "#fff" }}>
        <div>
          {/* {basic view filter} */}
          <Row className="flex-column">
            <Col className="col pb-2 pb-lg-3">
              <SelectInput
                label={"Make"}
                value={value?.make}
                options={filters?.make}
                onChange={(x) => onUpdate("make", x)}
                disabled={isLoading || loading}
                bold_class={
                  value.make === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
              />
            </Col>
            <Col className="col pb-2 pb-lg-3">
              <SelectInput
                label={"Model"}
                value={value?.model}
                options={
                  value?.make ? filters?.model[value?.make] : filters?.model['all']
                }
                onChange={(x) => onUpdate("model", x)}
                disabled={isLoading || loading}
                bold_class={
                  value.model === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
              />
            </Col>
            <Col className=" pb-2 pb-lg-3">
              <SelectInput
                label={"Base Price"}
                value={value?.base_msrp}
                options={filters?.base_msrp}
                onChange={(x) => onUpdate("base_msrp", x)}
                disabled={isLoading || loading}
                bold_class={
                  value.base_msrp === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
                isTextValueFilter={true}
              />
            </Col>
            <Col className="col pb-2 pb-lg-3">
              <SelectInput
                label={"Range (mi)"}
                value={value?.range}
                options={filters?.range}
                onChange={(x) => onUpdate("range", x)}
                disabled={isLoading || loading}
                bold_class={
                  value.range === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
                isTextValueFilter={true}
              />
            </Col>
            <Col>
              <span className="font-midium font-w">Model Year</span>
              <Form>
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="my-2 d-flex flex-wrap">
                    {filters?.model_year &&
                      filters.model_year?.map((model_year, keyIndex) => {
                        return (<Form.Check
                          key={keyIndex}
                          type={type}
                          id={`default-${type}`}
                          label={model_year}
                          className="me-4 form-checkbox"
                          style={{ fontWeight: "600" }}
                          value={model_year}
                          disabled={isLoading || loading}
                          onChange={handleModelYearChange}
                          checked={value?.model_year?.split(",").includes(String(model_year))}
                        />)
                      })}
                  </div>
                ))}
              </Form>
            </Col>
            <Col>
              <span className="font-midium font-w">Segment</span>
              <Form>
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="my-2 d-flex flex-wrap">
                    {filters?.segments &&
                      filters.segments?.map((segment, keyIndex) => {
                        return (
                          <Form.Check
                            key={"s_chk" + keyIndex}
                            type={type}
                            id={`default-${type}`}
                            value={segment?.value}
                            label={segment?.text}
                            className=" form-checkbox me-3"
                            style={{ fontWeight: "600" }}
                            onChange={handleSegmentChange}
                            checked={value?.segments?.split(",").includes(String(segment?.value))}
                            disabled={isLoading || loading}
                          />
                        )
                      })
                    }
                  </div>
                ))}
              </Form>
              <Form >
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="my-2 d-flex flex-wrap">
                    {filters?.segment &&
                      filters.segment?.map((segment, keyIndex) => {
                        return (
                          <Form.Check
                            key={"s_chk" + keyIndex}
                            type={type}
                            id={`default-${type}`}
                            value={segment}
                            label={segment}
                            className=" form-checkbox me-3"
                            style={{ fontWeight: "600" }}
                            onChange={handleSegmentChange}
                            disabled={isLoading || loading}
                          />
                        )
                      })
                    }
                  </div>
                ))}
              </Form>
            </Col>
            <Col>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Only show me vehicles eligible for Federal Tax Credit"
                  className="form-check font-mobile toggle-switch"
                  onChange={(x) => onUpdate("eligible", x.target.checked)}
                  checked={value?.eligible}
                  disabled={isLoading || loading}
                />
              </Form>
            </Col>
            <Col>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Only show vehicles with potential savings"
                  className="form-check font-mobile toggle-switch"
                  onChange={(x) => onUpdate("potential_savings", x.target.checked)}
                  checked={value?.potential_savings}
                  disabled={isLoading || loading}
                />
              </Form>
            </Col>
            <Col>
              <div className="d-flex flex-column pt-3">
                <button className="btn-learn-more"
                onClick={onFindClick}
                disabled={isLoading || loading}
                >Find</button>
              </div>
            </Col>
            <Col lg={2} className="col ">
              <Row className="pt-2">
                <Col className="">
                  <button
                    type="button"
                    className="text-dark button-set font-small link-button font-wx font-black"
                    onClick={onResetClick}
                    disabled={isLoading || loading}
                  >
                    Reset
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Fragment>
  );
};

const Input = ({
  label,
  value = "",
  onChange,
  placeHolder = "",
  disabled = false,
  handleBlur,
  customClassName = "",
}) => {
  const onUpdate = (e) => {
    onChange(e.target.value);
  };

  return (
    <Fragment>
      <label htmlFor={label.toLowerCase()} className="filter_label bold_text">
        {" "}
        {label}{" "}
      </label>
      <input
        type="text"
        placeholder={placeHolder}
        value={value}
        className={customClassName + " form-control"}
        onChange={onUpdate}
        disabled={disabled}
        onBlur={handleBlur}
      />
    </Fragment>
  );
};

const SelectInput = ({
  label,
  value = "",
  onChange,
  options = [],
  placeHolder = "-Any-",
  disabled = false,
  bold_class = "",
  customClassName = "",
  isTextValueFilter = false
}) => {
  const onUpdate = (e) => {
    onChange(e.target.value);
  };

  return (
    <Fragment>
      <label htmlFor={label.toLowerCase()} className="filter_label font-color ">
        {" "}
        {label}{" "}
      </label>
      <select
        className={
          bold_class +
          " form-select overflow-hidden border-w font-small" +
          customClassName
        }
        aria-label="Default select example"
        id={label.toLowerCase()}
        value={value}
        onChange={onUpdate}
        disabled={disabled}
      >
        <option value=""> {placeHolder} </option>
        {options?.map((item_val, item_key) => {
          return isTextValueFilter ? (<option value={item_val?.value} key={item_key}>
            {item_val?.text}
          </option>) : (
            <option value={item_val} key={item_key}>
              {item_val}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

const SelectWihoutAnyInput = ({
  label,
  value = "",
  onChange,
  options = [],
  disabled = false,
  bold_class = "",
  isTaxFillingStatus = false,
  customClassName = "",
  isHouseholdIncom = false
}) => {
  const onUpdate = (e) => {
    onChange(e.target.value);
  };

  return (
    <Fragment>
      <label htmlFor={label.toLowerCase()} className="filter_label bold_text">
        {" "}
        {label}{" "}
      </label>
      <select
        className={
          bold_class +
          " form-select overflow-hidden " +
          customClassName
        }
        aria-label="Default select example"
        id={label.toLowerCase()}
        value={value}
        onChange={onUpdate}
        disabled={disabled}
      >
        {options?.map((item_val, item_key) => {
          return (
            <option value={item_val} key={item_key}>
              {
                isTaxFillingStatus
                  ? item_val === "married"
                    ? "Married filing jointly"
                    : item_val.replaceAll("_", " ").charAt(0).toUpperCase() +
                    item_val.replaceAll("_", " ").slice(1)
                  : isHouseholdIncom
                    ? item_val.includes("Below ")
                      ? item_val.replace("Below ", "") + " or Less"
                      : item_val.replace("Above", "Over ")
                    : item_val}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

export { EVIncentiveFinderFiltersMobile, defaultValueIncentiveFinderFilters };
