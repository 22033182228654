import React, { useEffect } from 'react';
import { useAuth } from "./hooks/useAuth";
import { withRouter } from 'react-router-dom';

const ClosestChargingStation = () => {
  const [isLogin, userData] = useAuth();

  let zipCode = '';
  if(isLogin)
  {
    zipCode = userData?.zip_code
  }
  /*useEffect(() => {
    if (isLogin) {
      try {
        window.afdcStationsOptions = {
          country: "all",
          localeCountry: "US",
          path: "/find/nearest",
          query: {
            fuel: ["ELEC"],
            location: userData?.zip_code,
            ev_levels: ["dc_fast"],
            ev_connectors: ["J1772COMBO", "TESLA"]
          }
        };

        // Dynamically load the external script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = process.env.PUBLIC_URL + "/js/embed.js";
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
      } catch (error) {
        console.log(error);
        
      }
      // Set the options object before loading the external script


    }
  }, []);

  return (
    <div id="afdc-stations">
      <div id="afdc-stations-loading">Loading alternative fueling station locator...</div>
      <noscript>Please enable JavaScript to view the alternative fueling station locator.</noscript>
    </div>
  );*/

  return (
    <div style={{ width: '100%', height: '980px', border: 'none' }}>
      <iframe
        src={`${process.env.PUBLIC_URL}/afdc-station-locator.html?zipCode=${zipCode}`}
        title="Alternative Fuel Station Locator"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );

};

export default withRouter(ClosestChargingStation);
