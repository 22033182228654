import React, { Fragment, useState, useMemo } from "react";
import siffraLogo from "../../assets/img/favicon1.png";
import { Row, Col, Button, Offcanvas, Container } from 'react-bootstrap';
import NewIncentiveFinderByVehicleMobile from './NewIncentiveFinderByVehicleMobile';
import { EVIncentiveFinderFiltersMobile, defaultValueIncentiveFinderFilters } from "../../components/EVIncentiveFinderFiltersMobile";
import Drawer from "react-bottom-drawer";
import { IncentiveComparePopup } from "../../components/IncentiveComparePopup";
import { AuthButton } from "../../components/AuthButton";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { handleDragStart, handleContextMenu } from "../../helper/project_helper";
import { isEmpty, DateFormatterLastUpdated } from "../../helper/project_helper";

const MobileView = ({
	setFilter,
	setFilterState,
	setFirstLoading,
	setExpandedRows,
	setNotFound,
	incentives,
	loading,
	notFound,
	filterState,
	expandedRows,
	filter,
	reset,
	onClose,
	handleCompareCheck,
	compareVehicles,
	getCompareValue,
	valueCompareCheck,
	onChangeCompareCheck,
	onClickCompareVehicle,
	compareVehiclesError,
	history,
	lastUpdatedDate
}) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { stateEVIncentiveFinderFilters } = useSelector((state) => ({
		stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
	}));
	var isLoading = useMemo(() => {
		return stateEVIncentiveFinderFilters.loading || false;
	}, [stateEVIncentiveFinderFilters]);


	return (
		<Fragment>
			<div className={"mobile-view " + (incentives?.length === 0 && "blank-page")}>
				<section className="pt-5 m-min-height">
					<div className="Incentive-img">
						<div className="">
							<h1 className="font-white font-w font-px m-0">EV Incentive Finder</h1>
						</div>
					</div>
					<div className="table-body-color" style={{ overflow: "visible" }}>
						<div className="col-lg-2 col-md-12 side-relative">
							<Offcanvas show={show} onHide={handleClose} placement="end">
								<Offcanvas.Header closeButton>
								</Offcanvas.Header>
								<Offcanvas.Body className="py-0">
									<EVIncentiveFinderFiltersMobile
										value={filterState}
										onChange={setFilterState}
										onSearch={(dd) => {
											setFilter(dd);
											setFilterState(dd);
											setFirstLoading(false);
											setExpandedRows({});
											handleClose(true)
										}}
										reset={reset}
										lastFilter={filter}
										filterState={filterState}
										loading={loading}
									/>
								</Offcanvas.Body>
							</Offcanvas>
						</div>
						<Col className="table-body-color right-bar">
							<Container fluid className="position-stick" style={{background:"#fcfcfc"}}>
								<div className="d-flex justify-content-between align-items-center">
									<p className="py-4 me-3 text-font font-wxl font-color">
										Know which vehicle you want? Search for the full list of incentives with <AuthButton className="text-dark btn-incentive-finder p-0" onLogin={(s) => {
											history?.push("/incentivedetail", true);
										}}>
											<span className="font-midium font-wx font-color">Incentive Detail Finder.</span>
										</AuthButton>
									</p>
									<Button className="btn-learn-more size-change" onClick={handleShow}
										disabled={loading || isLoading}
									>Filters</Button>
								</div>
							</Container>
							<div className={"m-min-height"} style={{ overflowX: "hidden" }}>
								{!loading &&
									(incentives?.length > 0 ?
										<NewIncentiveFinderByVehicleMobile
											incentives={incentives}
											loading={loading}
											notFound={notFound}
											onSavingClick={(dd) => {
												setExpandedRows(dd);
											}}
											expandedRows={expandedRows}
											handleCompareCheck={handleCompareCheck}
											compareVehicles={compareVehicles}
											getCompareValue={getCompareValue}
											valueCompareCheck={valueCompareCheck}
											onChangeCompareCheck={onChangeCompareCheck}
										/> :
										<div className="bottom-border" style={{ background: '#fff' }}>
											<div className="d-flex align-items-center justify-content-between px-3 py-3">
												<span className="font-large font-w font-color">No results found.</span>
											</div>
										</div>)
								}
							</div>

						</Col>
					</div>
					<Container fluid>
						{/* <Col className="col-12 pt-4 text-center text-lg-start">
							{incentives?.length > 0 && !loading ?
								(<p className="text-font font-wxl font-black">* Not all customers qualify for incentives, check eligibility and redemption details.</p>) :
								notFound && !loading ? (<p>No matches found.</p>) :
									(<p></p>)}
							{incentives?.length > 0 && !loading ? <p className="text-font font-wxl font-black">* Check incentive eligibility and redemption details for additional requirements.</p> : ""}
						</Col> */}
						{!isEmpty(lastUpdatedDate) &&
							<Row className="pt-5 pb-2 px-2 last-updated-date">
								<Col>
									<label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(lastUpdatedDate)}</label>
								</Col>
							</Row>
						}
					</Container>
				</section>
				{/* <Drawer
					isVisible={compareVehicles?.length > 0 ? true : false}
					onClose={() => { }}
					className={`drawer ${isOpen ? "open" : ""}`}
				>
					<IncentiveComparePopup
						compareVehicles={compareVehicles}
						handleCompareCheck={handleCompareCheck}
						onClickCompareVehicle={onClickCompareVehicle}
						compareVehiclesError={compareVehiclesError}
					/>
				</Drawer> */}
				<div className={`drawer ${compareVehicles?.length > 0 ? "open" : "close-drawer"}`}>
					<IncentiveComparePopup
						compareVehicles={compareVehicles}
						handleCompareCheck={handleCompareCheck}
						onClickCompareVehicle={onClickCompareVehicle}
						compareVehiclesError={compareVehiclesError}
					/>
				</div>
			</div>
		</Fragment>
	)
};

export default withRouter(MobileView);