import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Nav, Tab, ButtonGroup, Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { isEmpty, NumberWithThousandSeparator, createSeoFriendlyUrl } from "../../helper/project_helper";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { AuthButton } from "../../components/AuthButton";
import { IncentiveDetailPopup } from "../../components/IncentiveDetailPopup";
import LoaderComponent from "../../components/LoaderComponent";
import MyLink from '../../components/MyLink';


const LeaseIncentiveSection = ({
    leaseDetail,
    filter,
    setUpdatedFilter,
    chargingIncentives,
    history,
    handleHideShowClick,
    expandedRows,
    isLoading
}) => {

    const onUpdate = (name, value1) => {
        let value_ = { ...filter };
        value_[name] = value1;
        setUpdatedFilter(value_);
        if (name === 'annual_mileage') {
            const input = document.querySelector('.annual_mileage_select');

            // Check if the element exists and has the class
            if (input && input.classList.contains('annual_mileage_select')) {
                input.blur(); // Give focus if the class is present
            }
        }
    }

    const [incentivesIds, setIncentivesIds] = useState("");

    useEffect(() => {
        const otherInc = [];
        leaseDetail?.vehicle_lease_data?.lease_data?.specialIncentives?.map((incentivesVal, incentivesKey) => {
            otherInc.push(incentivesVal?.incentive_id);
        });

        setIncentivesIds(otherInc?.join(","));
    }, [leaseDetail?.vehicle_lease_data?.lease_data?.specialIncentives])
    return (
        <Tab.Pane className="mt-5" eventKey="Lease">
            <Row className="g-4">
                <Col lg={6}>
                    <div className="d-flex flex-column border-line main-text-detail white-bg p-3 full-height">

                        <div className="">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <h4 className="me-1 font-px m-0 font-w m-text-center" style={{ opacity: "0.8" }}>Estimated Monthly Payment{!isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) && "*"}</h4>
                            </div>
                            {isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) ?
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h4 className="me-1 font-px m-0 font-w" style={{ color: "#21272A" }}>N/A</h4>
                                </div>
                                :
                                <h1 className="text-center d-flex justify-content-center align-items-center font-big1 font-wx mb-3">${NumberWithThousandSeparator(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month)}
                                    <p className="text-month" style={{ fontSize: "24px", fontWeight: "800" }}>&nbsp;/ month</p></h1>}
                            <Row className={"align-items-center g-1 mb-4 pb-1 m-text-center " + (leaseDetail?.lease_term_list && isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.lease_amount_per_month) && "mb-4")}>
                                <Col lg={2}></Col>
                                <Col lg={4} md={6}>
                                    {leaseDetail?.lease_term_list && (
                                        <Fragment>
                                            <p className="font-midium mb-1 font-Gunmetal" style={{ fontWeight: "600" }}>Lease Term</p>
                                            <ButtonGroup className="btn-group-main" aria-label="Basic example">
                                                {leaseDetail?.lease_term_list?.map((leaseTermVal, leaseTermKey) => {
                                                    return (
                                                        String(filter?.lease_term) === String(leaseTermVal) ? (
                                                            <Button value={leaseTermVal} className={String(filter?.lease_term) === String(leaseTermVal) ? "btn-group selected-lease-term" : "btn-group"} key={leaseTermKey}>{leaseTermVal}</Button>
                                                        ) : (
                                                            <Button
                                                                value={leaseTermVal}
                                                                className="btn-group"
                                                                // disabled={isLoading}
                                                                onClick={(e) => onUpdate("lease_term", e.target.value)}
                                                                key={leaseTermKey}
                                                            >{leaseTermVal}</Button>
                                                        )
                                                    )
                                                })}
                                            </ButtonGroup>
                                        </Fragment>
                                    )}
                                </Col>
                                <Col lg={4} md={6} className="m-center-section">
                                    {leaseDetail?.annual_mileage_list && (
                                        <Fragment>
                                            <span className="font-midium text-detaile" style={{ fontWeight: "700" }}>Estimated Annual Mileage</span>
                                            <Form.Select
                                                aria-label="Default select example"
                                                className="font-small font-wxl font-color annual_mileage_select"
                                                // disabled={isLoading}
                                                defaultValue={filter?.annual_mileage}
                                                onChange={(e) => onUpdate("annual_mileage", e.target.value)}
                                            >
                                                {leaseDetail?.annual_mileage_list?.map((annualMileageVal, annualMileageKey) => {
                                                    return (
                                                        <option
                                                            key={annualMileageKey}
                                                            value={annualMileageVal}
                                                        // selected={filter?.annual_mileage === annualMileageVal}
                                                        >{annualMileageVal ? NumberWithThousandSeparator(annualMileageVal) : 0}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Fragment>
                                    )}
                                </Col>
                                <Col lg={2}></Col>
                            </Row>
                            {!isEmpty(leaseDetail?.vehicle_lease_data?.lease_data?.down_payment) &&
                                <p className="mb-4 pb-1 text-center font-midium font-w font-black">*${leaseDetail?.vehicle_lease_data?.lease_data?.down_payment ? NumberWithThousandSeparator(leaseDetail?.vehicle_lease_data?.lease_data?.down_payment) : 0} down payment, taxes and fees due at signing.</p>}
                        </div>
                        {leaseDetail?.vehicle_lease_data?.total_potential_savings && leaseDetail?.vehicle_lease_data?.total_potential_savings.length > 0 &&
                            <div className="border-bottom border-top py-2">
                                {leaseDetail?.vehicle_lease_data?.total_potential_savings?.map(
                                    (object, i) => {
                                        return (
                                            <div style={{ position: "relative" }} key={i}>
                                                <div className={"d-flex justify-content-between py-2"} key={i}>
                                                    <span className="font-large font-w4 text-decoration-underline all-incentive"
                                                        onClick={() => handleHideShowClick("lease_potential_savings_" + i)}
                                                        aria-disabled={isLoading}>{isEmpty(object?.incentive_name_text) ? object?.incentive_name : object?.incentive_name_text}</span>
                                                    <span className="font-large font-w4 font-black">{
                                                        isEmpty(object?.incentive_amount_text) ? object?.incentive_amount : object?.incentive_amount_text
                                                    }</span>
                                                </div>
                                                {expandedRows["lease_potential_savings_" + i] &&
                                                    <div className="detail-popup">
                                                        <IncentiveDetailPopup handleHideShowClick={handleHideShowClick} IncentiveObject={object} specialIncentivesKey={"lease_potential_savings_" + i} />
                                                    </div>}
                                            </div>
                                        )
                                    }
                                )}
                                {/* <span className="font-smallx font-w3 font-color" style={{ marginTop: "-1rem" }}>Included as capitalized cost reduction.</span> */}
                            </div>}
                        {leaseDetail?.vehicle_lease_data?.specialIncentives && leaseDetail?.vehicle_lease_data?.specialIncentives?.length > 0 &&
                            <div className={(leaseDetail?.vehicle_lease_data?.total_potential_savings || leaseDetail?.vehicle_lease_data?.total_potential_savings === 0) && "border-top"}>
                                <h6 className="m-0 mt-3 mb-1 incentiver-title">Other Incentives</h6>
                                {leaseDetail?.vehicle_lease_data?.specialIncentives?.map((specialIncentivesVal, specialIncentivesKey) => {
                                    return (
                                        <div style={{ position: "relative" }} key={specialIncentivesKey}>
                                            <div className="my-2 d-flex justify-content-between mb-1">
                                                <span className="all-incentive saving-detail"
                                                    style={{ cursor: "pointer" }}
                                                    aria-disabled={isLoading}
                                                    onClick={() => handleHideShowClick("other_incentive_" + specialIncentivesKey)}
                                                >{isEmpty(specialIncentivesVal?.incentive_name_text) ? specialIncentivesVal?.incentive_name : specialIncentivesVal?.incentive_name_text}</span>
                                                <span className="">{isEmpty(specialIncentivesVal?.incentive_amount_text) ? specialIncentivesVal?.incentive_amount : specialIncentivesVal?.incentive_amount_text}</span>
                                            </div>
                                            {expandedRows["other_incentive_" + specialIncentivesKey] &&
                                                <div className="detail-popup">
                                                    <IncentiveDetailPopup handleHideShowClick={handleHideShowClick} IncentiveObject={specialIncentivesVal} specialIncentivesKey={"other_incentive_" + specialIncentivesKey} />
                                                </div>}
                                        </div>
                                    )
                                })}
                            </div>
                        }

                        {chargingIncentives && chargingIncentives.length > 0 && (
                            <div className={"border-bottom pb-3 pt-2 mt-2 " + (((leaseDetail?.vehicle_lease_data?.specialIncentives && leaseDetail?.vehicle_lease_data?.specialIncentives.length) || !leaseDetail?.vehicle_lease_data?.specialIncentives) > 0 && "border-top")}>
                                <Fragment>
                                    <h6 className="m-0 mt-2 mb-1 incentiver-title">Charging Incentives</h6>
                                    {chargingIncentives?.map((chargingIncentiveValue, chargingIncentivesKey) => {
                                        return (
                                            <div style={{ position: "relative" }} key={chargingIncentivesKey}>
                                                <div className="d-flex justify-content-between mt-2">
                                                    <span
                                                        className="font-large font-w4 font-Gunmetal text-decoration-underline detail-incentive-name all-incentive pe-5 cursor-pointer"
                                                        onClick={() => handleHideShowClick("charging_incentive_" + chargingIncentivesKey)}
                                                        aria-disabled={isLoading}
                                                    >{isEmpty(chargingIncentiveValue?.incentive_name_text) ? chargingIncentiveValue?.incentive_name : chargingIncentiveValue?.incentive_name_text}</span>
                                                    <div className="detail-incentive-amount-div">
                                                        <span
                                                            className="font-large font-w4 font-black detail-incentive-amount"
                                                        >{isEmpty(chargingIncentiveValue?.incentive_amount_text) ? chargingIncentiveValue?.incentive_amount : chargingIncentiveValue?.incentive_amount_text}</span>
                                                    </div>
                                                </div>
                                                {expandedRows["charging_incentive_" + chargingIncentivesKey] &&
                                                    <div className="detail-popup">
                                                        <IncentiveDetailPopup handleHideShowClick={handleHideShowClick} IncentiveObject={chargingIncentiveValue} specialIncentivesKey={"charging_incentive_" + chargingIncentivesKey} />
                                                    </div>
                                                }
                                                {/* <div className="detail-popup">
                                                <IncentiveDetailPopup />
                                            </div> */}
                                            </div>
                                        );
                                    })}

                                </Fragment>
                            </div>
                        )}

                        <span className="pt-4 pb-2 detail-search-link d-block align-items-center mt-3 font-midium">For a full list of State, Local, and Utility incentives that may be available for your location, visit&nbsp;
                            <AuthButton className="text-dark btn-incentive-finder font-wx p-0" onLogin={(s) => {
                                history?.push("/incentivedetail/Lease/" + leaseDetail?.vehicle_lease_data?.lease_data?.vehicle_id + "/" +
                                    createSeoFriendlyUrl(leaseDetail?.vehicle_lease_data?.lease_data?.make) + "/" + createSeoFriendlyUrl(leaseDetail?.vehicle_lease_data?.lease_data?.model) + "/" + leaseDetail?.vehicle_lease_data?.lease_data?.model_year + "/" + createSeoFriendlyUrl(leaseDetail?.vehicle_lease_data?.lease_data?.trim), true);
                            }}>
                                Incentive Detail Finder
                            </AuthButton><span className="small-space">.</span></span>

                        <div className="d-flex flex-column d-none">
                            <MyLink className="btn-learn-more text-center" to={"/incentivesummary/" + leaseDetail?.vehicle_lease_data?.lease_data?.vehicle_id + "/Lease/" + leaseDetail?.vehicle_lease_data?.lease_data
                                ?.vehicle_condition + "/" + incentivesIds + "/0/" + filter?.lease_term + "/" + filter?.annual_mileage} rel="noopener noreferrer">View Summary</MyLink>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="main-text-detail p-3 white-bg full-height">
                        <h5 className="font-px font-w font-Gunmetal mb-3">Leasing Information & Disclosures</h5>
                        <p className="font-midium font-w4 font-black mb-3">These are estimated monthly payments if you participate in applicable lease agreement.</p>
                        <p className="font-midium font-w4 font-black mb-3">Estimates are subject to lease approval.</p>
                        <p className="font-midium font-w4 font-black mb-3">Taxes and fees may vary by state.</p>
                        <p className="font-midium font-w4 font-black mb-3">Does not include maintenance or insurance.</p>
                        <p className="font-midium font-w4 font-black mb-3">Security deposit not required.</p>
                        <p className="font-midium font-w4 font-black mb-3">Available incentives will be applied towards the amount due at signing or Capitalized Cost Reduction.</p>
                    </div>
                </Col>
            </Row>
        </Tab.Pane>
    );
}

export default withRouter(LeaseIncentiveSection);