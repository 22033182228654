import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SkeletonComponent from "../SkeletonComponent"
import { Row, Col, Container, Form, Button, CloseButton } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';

const DetailLoder = () => {
    return (
        <Fragment>
            <div className="px-5">
                <section className="">
                    <Row>
                        <Col lg={3}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-1" />
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-2" />
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-2" />
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-3" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-3" />
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-4" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-4" />
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-5" />
                                <SkeletonComponent count={1} className="height-6 mt-4" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="">
                                <SkeletonComponent count={1} className="height-7" />
                            </div>
                            <Row className="mt-4">
                                <Col lg={8}>
                                    <div className="">
                                        <SkeletonComponent count={1} className="height-8" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="">
                                        <SkeletonComponent count={1} className="height-8" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </div>
        </Fragment>
    );
}

export default withRouter(DetailLoder);