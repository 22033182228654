import {
  INCENTIVE_SUMMARY,
  SET_INCENTIVE_SUMMARY_LOADING,
  UPDATE_INCENTIVE_SUMMARY_DATA,
} from "./actionTypes";


export const incentiveSummary = () => {
  return {
    type: INCENTIVE_SUMMARY,
    payload: {},
  };
};

export const setIncentiveSummaryLoading = (loading) => {
  return {
    type: SET_INCENTIVE_SUMMARY_LOADING,
    payload: { loading },
  };
};

export const updateIncentiveSummary = (data) => {
  return {
    type: UPDATE_INCENTIVE_SUMMARY_DATA,
    payload: { data },
  };
};
