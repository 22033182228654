import {
  USER_DETAILS,
  SET_USER_DETAILS_LOADING,
  UPDATE_USER_DETAILS_DATA,
  UPDATE_USER_TEMP_DATA
} from "./actionTypes";
import * as helper from "../../helper/sessionStorage";

const lastLoginUserData = helper.getLoginUserData();


const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: lastLoginUserData || false,
  isLogin: lastLoginUserData ? true : false,
  apiCallCount: 0,
  tempData: false
};

const UserDetails = (state = initialState, action) => {

  switch (action.type) {
    case USER_DETAILS:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_USER_DETAILS_LOADING: {
      let apiCallCount = state.apiCallCount || 0;

      if (action.payload.loading === true) {
        apiCallCount = apiCallCount + 1;
      }

      state = {
        ...state,
        loading: action.payload.loading,
        apiCallCount: apiCallCount,
      };

    }
      break;
    case UPDATE_USER_DETAILS_DATA: {
      let isLogin = false;

      if (action.payload.data !== false && action.payload.data !== null ) {
        isLogin = true;
      }

      state = {
        ...state,
        ...action.payload.data,
        isLogin: isLogin
      };

    }
      break;
    case UPDATE_USER_TEMP_DATA: {
      state = {
        ...state,
        tempData: action.payload.data,
      };

    }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserDetails;
