import React from "react";


const Unauthorized = () => {

    return (
        <div>
            <h1 className="text-center">401 Access Denied</h1>
        </div>
    );
}


export default Unauthorized;