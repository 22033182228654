import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import CompareVehiclesDesktop from "./CompareVehiclesDesktop";
import CompareVehiclesMobile from "./CompareVehiclesMobile";
import { withRouter, useParams } from "react-router-dom";
import { useMyHistory } from "../../components/hooks/useMyHistory";
import { useSelector, useDispatch } from "react-redux";
import { setCompareVehiclesLoading, updateCompareVehicles, updateAuthPoupState, updateUserDetails } from "../../store/action";
import * as apiHelper from "../../helper/api_helper";
import { ArrayAsc, isEmpty } from "../../helper/project_helper";
import PageLoaderComponent from "../../components/PageLoaderComponent";
import { clearLoginUserData } from "../../helper/sessionStorage";
import { defaultPopUpState } from '../../helper/project_helper';
import { useAuth } from "../../components/hooks/useAuth";
import CompareLoader from "../../components/SkeletonLoder/CompareLoader";


const useDefaultValueCompareVehicle = () => {
    let { compare_vehicles } = useParams();
    return {
        compare_vehicles: JSON.parse(compare_vehicles) || [],
        is_more: '2'
    };
};

const CompareVehicles = () => {
    const [hideViewPopup, setHideViewPopup] = useState({});
    const [isClosePopup, setIsClosePopup] = useState(false);
    const [hideViewRistrictedSection, setHideViewRistrictedSection] = useState({});
    const [displaySkeletonView, setDisplaySkeletonView] = useState(true);
    const [filter, setFilter] = useState(useDefaultValueCompareVehicle());
    const [filterList, setFilterList] = useState([]);
    const [filterState, setFilterState] = useState(useDefaultValueCompareVehicle());
    const [isLogin] = useAuth();
    const [fieldError, setFieldError] = useState({});
    const [popupLoading, setPopupLoading] = useState(false);
    let { compare_vehicles } = useParams();


    const handleChangeClick = (rowId, index = '') => {
        setHideViewPopup({});
        if (index !== '' && hideViewPopup[rowId]) {
            const currentCompareData = JSON.parse(compare_vehicles);
            const tempFilter = { ...filter };
            tempFilter.compare_vehicles[index] = currentCompareData?.[index];
            setFilter(tempFilter);
            setFilterState(tempFilter);
            setIsClosePopup(true);
        } else {
            setHideViewPopup((prev) => ({
                ...prev,
                [rowId]: !prev[rowId],
            }));
        }

    };

    const dispatch = useDispatch();
    const history = useMyHistory();

    const { stateCompareVehicles } = useSelector((state) => ({
        stateCompareVehicles: state.CompareVehicles,
    }));

    useEffect(() => {
        if (stateCompareVehicles?.data !== false && displaySkeletonView === true && stateCompareVehicles?.loading === false) {
            setTimeout(() => {
                setDisplaySkeletonView(false);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateCompareVehicles]);

    const compareData = useMemo(() => {
        if (stateCompareVehicles.data !== false) {
            return stateCompareVehicles.data?.compare_data;
        }
        return null;
    }, [stateCompareVehicles]);

    const page_title = useMemo(() => {
        if (stateCompareVehicles?.data !== false) {
            if (stateCompareVehicles?.data?.page_title !== null) {
                return stateCompareVehicles?.data?.page_title;
            }
        }
        return null;
    }, [stateCompareVehicles]);

    const page_meta_description = useMemo(() => {
        if (stateCompareVehicles?.data !== false) {
            if (stateCompareVehicles?.data?.page_meta_description !== null) {
                return stateCompareVehicles?.data?.page_meta_description;
            }
        }
        return null;
    }, [stateCompareVehicles]);



    useEffect(() => {
        if (!isEmpty(page_title)) {
            document.title = page_title;
        }
        if (!isEmpty(page_meta_description)) {
            document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page_title, page_meta_description]);


    useEffect(() => {

        if (isClosePopup === false) {
            if (stateCompareVehicles.loading === false && stateCompareVehicles.error === '') {
                getCompareVehiclesData(filter);
            }
        } else {
            setIsClosePopup(false);
            dispatch(updateCompareVehicles({
                loading: false,
                status: true,
                filter: filterState
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState]);

    useEffect(() => {
        async function getData() {

            if (stateCompareVehicles.loading === false && stateCompareVehicles.error === '') {
                const listArray = [];
                compareData?.map(async (vehicleData, vehicleDataKey) => {
                    const list = await getPopupData(filter?.compare_vehicles?.[vehicleDataKey]);
                    listArray[vehicleDataKey] = list;
                });
                setFilterList(listArray);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareData, isClosePopup]);

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    const onLogin = (x) => {
        if (x === true) {
            if (stateCompareVehicles?.filter?.is_more === '2') {
                setFilter({ ...filter, is_more: '1' });
                setFilterState({ ...filterState, is_more: '1' });
            }
            handleRistrictedSection("ristricted_section");
        }
    }

    async function getCompareVehiclesData(params = {}) {

        if (stateCompareVehicles.filter !== undefined && stateCompareVehicles.filter !== false) {
            const lastFilter = ArrayAsc(JSON.parse(compare_vehicles));
            const currentFilter = ArrayAsc(filter?.compare_vehicles);

            if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter) && stateCompareVehicles?.filter?.is_more === currentFilter?.is_more) {
                return false;
            }
        }

        dispatch(setCompareVehiclesLoading(true));
        try {
            const response = await apiHelper.postCompareVehicle(params);
            var resData = [];
            if (response.data.status === 1) {
                resData = response?.data?.body;
                if (JSON.stringify(filterState?.compare_vehicles) !== compare_vehicles) {
                    history.push('/comparevehicles/' + JSON.stringify(filterState?.compare_vehicles), { shallow: true });
                }
                dispatch(updateCompareVehicles({
                    loading: false,
                    status: true,
                    data: resData,
                    filter: filterState
                }));
            } else if (response.data.status === 2) {
                dispatch(setCompareVehiclesLoading(false));
                askLogin();
            } else {
                dispatch(updateCompareVehicles({
                    loading: false,
                    status: false,
                    error: response?.data,
                }));
            }


        } catch (error) {
            console.error(error);
            dispatch(updateCompareVehicles({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    async function onChangeCompareVehicle(index) {
        const checkMake = filter?.compare_vehicles?.some(vehicle => vehicle.make === "");
        const checkModel = filter?.compare_vehicles?.some(vehicle => vehicle.model === "");
        let checkTrim = false

        filter?.compare_vehicles?.map((val, key) => {
            if (filterList?.[key]?.trim?.length > 0 && filter?.compare_vehicles?.[key]?.trim === '') {
                checkTrim = true;
            }
        });

        let fieldErrorList = { ...fieldError };
        if (checkMake) {
            fieldErrorList[index + "make"] = true;
        } else {
            delete fieldErrorList?.[index + "make"];
        }
        if (checkModel) {
            fieldErrorList[index + "model"] = true;
        } else {
            delete fieldErrorList?.[index + "model"];
        }
        if (checkTrim) {
            fieldErrorList[index + "trim"] = true;
        } else {
            delete fieldErrorList?.[index + "trim"];
        }

        setFieldError(fieldErrorList);
        if (!checkMake && !checkModel && !checkTrim) {
            setHideViewPopup({});
            setFilterState(filter);
        }
    }

    async function onChangePopUpField(index, name, value) {
        const tempState = { ...filter?.compare_vehicles };
        const tempFilterList = { ...filterList };


        tempState[index][name] = value;

        if (name === "make") {
            tempState[index].model = "";
            tempState[index].trim = "";
            tempState[index].model_year = "";
        }
        if (name === "model") {
            tempState[index].trim = "";
            tempState[index].model_year = "";
        }
        if (name === "trim") {
            tempState[index].model_year = "";
        }

        const tempFilter = [];
        Object.keys(tempState).map((index) => {
            tempFilter.push(tempState[index]);
        });


        if (stateCompareVehicles.loading === false && stateCompareVehicles.error === '') {
            let list = await getPopupData(tempFilter[index]);

            if (list?.model_year?.length === 1) {
                tempFilter[index].model_year = String(list?.model_year[0]);
            }
            if (tempFilter[index]?.make !== list?.selected_make && name === 'model' && !isEmpty(list?.selected_make)) {
                tempFilter[index].make = list?.selected_make;
            }

            if (name !== 'model') {
                list = await getPopupData(tempFilter[index]);
                if (list?.model?.length === 1) {
                    if (list?.model[0] !== tempFilter[index].model) {
                        tempFilter[index].model = list?.model[0];
                        list = await getPopupData(tempFilter[index]);
                        if (tempFilter[index]?.make !== list?.selected_make && !isEmpty(list?.selected_make)) {
                            tempFilter[index].make = list?.selected_make;
                        }
                    }
                }
            }
            if (name !== 'trim') {
                list = await getPopupData(tempFilter[index]);
                if (list?.trim?.length === 1) {
                    if (list?.trim[0] !== tempFilter[index]?.trim) {
                        tempFilter[index].trim = list?.trim[0];
                    }
                }
            }


            tempFilterList[index] = list;
        }

        setFilterList(tempFilterList);
        setFilter({ ...filter, compare_vehicles: tempFilter });
        // setTimeout(async () => {
        //     if (stateCompareVehicles.loading === false && stateCompareVehicles.error === '') {
        //         const list = await getPopupData(tempFilter[index]);
        //         if (list?.model?.length === 1) {
        //             if (list?.model[0] !== tempFilter[index].model) {
        //                 tempFilter[index].model = list?.model[0];
        //                 setFilter({ ...filter, compare_vehicles: tempFilter });
        //             }
        //         }
        //     }
        // }, 100);
        // setTimeout(async () => {
        //     if (stateCompareVehicles.loading === false && stateCompareVehicles.error === '') {
        //         const list = await getPopupData(tempFilter[index]);
        //         if (list?.trim?.length === 1) {
        //             if (list?.trim[0] !== tempFilter[index]?.trim) {
        //                 tempFilter[index].trim = list?.trim[0];
        //                 setFilter({ ...filter, compare_vehicles: tempFilter });
        //             }
        //         }
        //     }
        // }, 100);
    }

    async function getPopupData(params = {}) {
        // dispatch(setCompareVehiclesLoading(true));
        setPopupLoading(true);
        try {
            const response = await apiHelper.postCompareVehicleFilter(params);
            var resData = [];
            if (response.data.status === 1) {
                resData = response?.data?.body;
            }

            setPopupLoading(false);
            // dispatch(setCompareVehiclesLoading(false));
            return resData
        } catch (error) {
            console.error(error);
            setPopupLoading(false);
            // dispatch(setCompareVehiclesLoading(false));
        }
    }

    const handleRistrictedSection = (rowId) => {
        setHideViewRistrictedSection((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };

    useEffect(() => {
        if (isLogin === false) {
            setHideViewRistrictedSection({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            // setHideViewPopup({});
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {displaySkeletonView &&
                <CompareLoader />}
            {/* {!displaySkeletonView && */}
            <PageLoaderComponent loading={stateCompareVehicles?.loading} />
            {/* } */}


            {isEmpty(stateCompareVehicles?.error?.msg) && !displaySkeletonView &&
                <Fragment>
                    <CompareVehiclesDesktop
                        handleChangeClick={handleChangeClick}
                        hideViewPopup={hideViewPopup}
                        divRef={divRef}
                        compareData={compareData}
                        filter={filter}
                        filterState={filterState}
                        setFilter={setFilter}
                        setFilterState={setFilterState}
                        handleRistrictedSection={handleRistrictedSection}
                        hideViewRistrictedSection={hideViewRistrictedSection}
                        filterList={filterList}
                        onChangePopUpField={onChangePopUpField}
                        onChangeCompareVehicle={onChangeCompareVehicle}
                        tooltipText={stateCompareVehicles.data?.tooltips}
                        fieldError={fieldError}
                        onLogin={onLogin}
                        popupLoading={popupLoading}
                        isLoading={stateCompareVehicles?.loading}
                    />
                    <CompareVehiclesMobile
                        isLoading={stateCompareVehicles?.loading}
                        handleChangeClick={handleChangeClick}
                        hideViewPopup={hideViewPopup}
                        divRef={divRef}
                        compareData={compareData}
                        filter={filter}
                        setFilter={setFilter}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        handleRistrictedSection={handleRistrictedSection}
                        hideViewRistrictedSection={hideViewRistrictedSection}
                        filterList={filterList}
                        onChangeCompareVehicle={onChangeCompareVehicle}
                        onChangePopUpField={onChangePopUpField}
                        onLogin={onLogin}
                    />
                </Fragment>}
        </Fragment>
    )
}

export default withRouter(CompareVehicles);