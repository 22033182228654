import {
  INCENTIVE_FINDER_FILTERS,
  SET_INCENTIVE_FINDER_FILTERS_LOADING,
  UPDATE_INCENTIVE_FINDER_FILTERS_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
  filter: {}
};

const IncentiveFinderFilters = (state = initialState, action) => {

  switch (action.type) {
    case INCENTIVE_FINDER_FILTERS:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_INCENTIVE_FINDER_FILTERS_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_INCENTIVE_FINDER_FILTERS_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default IncentiveFinderFilters;
