import React, { Fragment } from "react";
import { AuthButton } from "../../components/AuthButton";
import { Row, Col, Container } from 'react-bootstrap';
import NewIncentiveFinderByVehicleTable from './NewIncentiveFinderByVehicleTable';
import { EVIncentiveFinderFilters } from "../../components/EVIncentiveFinderFilters";
import { withRouter } from "react-router-dom";
import { isEmpty, DateFormatterLastUpdated } from "../../helper/project_helper";
import infosvg from '../../assets/img/bi-info-circle.svg'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const DesktopView = ({
	setFilter,
	setFilterState,
	setFirstLoading,
	setExpandedRows,
	setNotFound,
	incentives,
	loading,
	notFound,
	filterState,
	expandedRows,
	filter,
	reset,
	onClickCompareVehicle,
	history,
	handleCompareCheck,
	compareVehicles,
	getCompareValue,
	valueCompareCheck,
	onChangeCompareCheck,
	compareVehiclesError,
	tooltipText,
	lastUpdatedDate
}) => {

	return (
		<section className="pt-3 desktop-view">
			<div className="Incentive-img">
				<div className="text-p-set">
					<h1 className="font-white m-0" style={{ fontSize: "56px", fontWeight: "600" }}>EV Incentive Finder</h1>
					{/* <p className="text-light" style={{ fontSize: "16px", fontWeight: "500", lineHeight: "100%" }}>Siffra EV Incentive Finder provides the latest information on electric vehicles, charging, and incentives in an easy to understand format. It is designed to offer an integrated product experience as you navigate between vehicle, charging, and incentives information.</p> */}
				</div>
			</div>
			<Row className="row-bg mx-5" id="scroll_here">
				<Col lg={3} md={3} className="pe-4 ps-0" style={{ position: "sticky", top: "0px", zIndex: "10", alignSelf: "flex-start" }} >
					<div className="px-3 pt-4" style={{ background: "#fff" }}>
						<EVIncentiveFinderFilters
							value={filterState}
							onChange={setFilterState}
							onSearch={(dd) => {
								setFilter(dd);
								setFilterState(dd);
								setFirstLoading(false);
								setExpandedRows({});
							}}
							reset={reset}
							lastFilter={filter}
							onClickCompareVehicle={onClickCompareVehicle}
							compareVehicles={compareVehicles}
							compareVehiclesError={compareVehiclesError}
							loading={loading}
						/>
					</div>
				</Col>
				<Col lg={9} md={9} className=" table-body-color right-bar" style={{ paddingLeft: "0px", paddingRight: "0px" }}>

					<div className="filter-sicky">
						<span className="pt-4 pb-2 detail-search-link d-flex align-items-center" style={{ background: "#fcfcfc" }}>Know which vehicle you want? Search for full list of incentives with&nbsp;
							<AuthButton className="text-dark btn-incentive-finder font-wx px-0" onLogin={(s) => {
								history?.push("/incentivedetail", true);
							}}>Incentive Detail Finder
							</AuthButton>.</span>
						<div className="NewThead">
							<div className="table-gray d-flex align-items-center table-header px-3">
								{/* <td className="py-2 sticky-table-header width-75">Make</td> */}
								<div style={{ width: "21.5%" }}
									className={
										incentives
											? "py-2 table-header header-width"
											: "fix-width py-2 table-header"
									}
								>
									Vehicle
								</div>
								<div style={{ width: "15%" }} className="py-2 table-header ">Model Year</div>
								<div style={{ width: "15%" }} className="py-2 table-header  v_class">
									Segment
								</div>
								<div style={{ width: "15%" }} className="py-2 table-header ">
									Range (mi)
								</div>
								<div style={{ width: "15%" }} className="py-2 table-header ">
									<div className="d-flex align-items-center">
										<span>Base Price</span>
										{!isEmpty(tooltipText?.[1]) &&
											<OverlayTrigger
												placement="bottom"
												delay={{ show: 250, hide: 400 }}
												overlay={
													<Tooltip className="custom-tooltip base-price-tooltip mt-2">
														<div className="tc-text">
															{tooltipText?.[1]}
														</div>
													</Tooltip>
												}
											>
												<i className="d-flex align-items-center justify-content-center ms-1 svg-icon">
													<svg className="overflow-visible" width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M6.5 11.25C5.10761 11.25 3.77226 10.6969 2.78769 9.71231C1.80312 8.72775 1.25 7.39239 1.25 6C1.25 4.60761 1.80312 3.27226 2.78769 2.28769C3.77226 1.30312 5.10761 0.75 6.5 0.75C7.89239 0.75 9.22775 1.30312 10.2123 2.28769C11.1969 3.27226 11.75 4.60761 11.75 6C11.75 7.39239 11.1969 8.72775 10.2123 9.71231C9.22775 10.6969 7.89239 11.25 6.5 11.25ZM6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0C4.9087 0 3.38258 0.632141 2.25736 1.75736C1.13214 2.88258 0.5 4.4087 0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C3.38258 11.3679 4.9087 12 6.5 12Z" fill="white" />
														<path d="M7.1975 4.941L5.48 5.15625L5.4185 5.44125L5.756 5.5035C5.9765 5.556 6.02 5.6355 5.972 5.85525L5.4185 8.45625C5.273 9.129 5.49725 9.4455 6.0245 9.4455C6.43325 9.4455 6.908 9.2565 7.12325 8.997L7.18925 8.685C7.03925 8.817 6.82025 8.8695 6.67475 8.8695C6.4685 8.8695 6.3935 8.72475 6.44675 8.46975L7.1975 4.941ZM7.25 3.375C7.25 3.57391 7.17098 3.76468 7.03033 3.90533C6.88968 4.04598 6.69891 4.125 6.5 4.125C6.30109 4.125 6.11032 4.04598 5.96967 3.90533C5.82902 3.76468 5.75 3.57391 5.75 3.375C5.75 3.17609 5.82902 2.98532 5.96967 2.84467C6.11032 2.70402 6.30109 2.625 6.5 2.625C6.69891 2.625 6.88968 2.70402 7.03033 2.84467C7.17098 2.98532 7.25 3.17609 7.25 3.375Z" fill="white" />
													</svg>
												</i>
											</OverlayTrigger>
										}
									</div>
								</div>
								<div style={{ width: "15%" }} className="py-2 table-header text-center">
									<div className="d-flex align-items-center">
										<span style={{ lineHeight: "1" }}>
											Potential
											<br />
											<span className="me-1">Savings</span>
										</span>
										{!isEmpty(tooltipText?.[2]) &&
											<OverlayTrigger
												placement="bottom"
												delay={{ show: 250, hide: 400 }}
												overlay={
													<Tooltip className="custom-tooltip base-price-tooltip mt-2">
														<div className="tc-text">
															{/* Estimated Incentives available for purchasing a vehicle. Subject to buyer eligibility, provider requirements, and may vary by State. For more information, check Vehicle Detail Page. */}
															{tooltipText[2]}
														</div>
													</Tooltip>
												}
											>
												<i className="d-flex align-items-center justify-content-center ms-1 svg-icon">
													<svg className="overflow-visible" width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M6.5 11.25C5.10761 11.25 3.77226 10.6969 2.78769 9.71231C1.80312 8.72775 1.25 7.39239 1.25 6C1.25 4.60761 1.80312 3.27226 2.78769 2.28769C3.77226 1.30312 5.10761 0.75 6.5 0.75C7.89239 0.75 9.22775 1.30312 10.2123 2.28769C11.1969 3.27226 11.75 4.60761 11.75 6C11.75 7.39239 11.1969 8.72775 10.2123 9.71231C9.22775 10.6969 7.89239 11.25 6.5 11.25ZM6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0C4.9087 0 3.38258 0.632141 2.25736 1.75736C1.13214 2.88258 0.5 4.4087 0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C3.38258 11.3679 4.9087 12 6.5 12Z" fill="white" />
														<path d="M7.1975 4.941L5.48 5.15625L5.4185 5.44125L5.756 5.5035C5.9765 5.556 6.02 5.6355 5.972 5.85525L5.4185 8.45625C5.273 9.129 5.49725 9.4455 6.0245 9.4455C6.43325 9.4455 6.908 9.2565 7.12325 8.997L7.18925 8.685C7.03925 8.817 6.82025 8.8695 6.67475 8.8695C6.4685 8.8695 6.3935 8.72475 6.44675 8.46975L7.1975 4.941ZM7.25 3.375C7.25 3.57391 7.17098 3.76468 7.03033 3.90533C6.88968 4.04598 6.69891 4.125 6.5 4.125C6.30109 4.125 6.11032 4.04598 5.96967 3.90533C5.82902 3.76468 5.75 3.57391 5.75 3.375C5.75 3.17609 5.82902 2.98532 5.96967 2.84467C6.11032 2.70402 6.30109 2.625 6.5 2.625C6.69891 2.625 6.88968 2.70402 7.03033 2.84467C7.17098 2.98532 7.25 3.17609 7.25 3.375Z" fill="white" />
													</svg>
												</i>
											</OverlayTrigger>
										}
									</div>
								</div>
								<div style={{ width: "15%" }} className="py-2 table-header text-center requirement_columns">
									<div className="d-flex align-items-center">
										<span style={{ lineHeight: "1", textAlign: "justify" }}>
											Federal
											<br />
											Tax Credit
											<br />
											Eligibility
										</span>
										{!isEmpty(tooltipText?.[3]) &&
											<OverlayTrigger
												placement="bottom"
												delay={{ show: 250, hide: 400 }}
												overlay={
													<Tooltip className="custom-tooltip base-price-tooltip mt-2">
														<div className="tc-text">
															{tooltipText?.[3]}
														</div>
													</Tooltip>
												}
											>
												<i className="d-flex align-items-center justify-content-center svg-icon ms-1">
													<svg className="overflow-visible" width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M6.5 11.25C5.10761 11.25 3.77226 10.6969 2.78769 9.71231C1.80312 8.72775 1.25 7.39239 1.25 6C1.25 4.60761 1.80312 3.27226 2.78769 2.28769C3.77226 1.30312 5.10761 0.75 6.5 0.75C7.89239 0.75 9.22775 1.30312 10.2123 2.28769C11.1969 3.27226 11.75 4.60761 11.75 6C11.75 7.39239 11.1969 8.72775 10.2123 9.71231C9.22775 10.6969 7.89239 11.25 6.5 11.25ZM6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0C4.9087 0 3.38258 0.632141 2.25736 1.75736C1.13214 2.88258 0.5 4.4087 0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C3.38258 11.3679 4.9087 12 6.5 12Z" fill="white" />
														<path d="M7.1975 4.941L5.48 5.15625L5.4185 5.44125L5.756 5.5035C5.9765 5.556 6.02 5.6355 5.972 5.85525L5.4185 8.45625C5.273 9.129 5.49725 9.4455 6.0245 9.4455C6.43325 9.4455 6.908 9.2565 7.12325 8.997L7.18925 8.685C7.03925 8.817 6.82025 8.8695 6.67475 8.8695C6.4685 8.8695 6.3935 8.72475 6.44675 8.46975L7.1975 4.941ZM7.25 3.375C7.25 3.57391 7.17098 3.76468 7.03033 3.90533C6.88968 4.04598 6.69891 4.125 6.5 4.125C6.30109 4.125 6.11032 4.04598 5.96967 3.90533C5.82902 3.76468 5.75 3.57391 5.75 3.375C5.75 3.17609 5.82902 2.98532 5.96967 2.84467C6.11032 2.70402 6.30109 2.625 6.5 2.625C6.69891 2.625 6.88968 2.70402 7.03033 2.84467C7.17098 2.98532 7.25 3.17609 7.25 3.375Z" fill="white" />
													</svg>
												</i>
											</OverlayTrigger>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={"table-responsive height-100"}>
						{incentives?.length > 0 ?
							<NewIncentiveFinderByVehicleTable incentives={incentives} loading={loading} notFound={notFound} onSavingClick={(dd) => {
								setExpandedRows(dd);
							}}
								expandedRows={expandedRows}
								handleCompareCheck={handleCompareCheck}
								compareVehicles={compareVehicles}
								getCompareValue={getCompareValue}
								valueCompareCheck={valueCompareCheck}
								onChangeCompareCheck={onChangeCompareCheck}
								tooltipText={tooltipText}

							/> :
							!loading &&
							<div className={"bottom-border d-flex align-items-center justify-content-between main-animation"} style={{ background: '#fff', height: "64px", overflow: "visible" }}>
								<div className="px-4"><span className="font-large font-w font-color">No results found.</span></div>
							</div>
						}


					</div>
				</Col>
			</Row>
			{!isEmpty(lastUpdatedDate) &&
				<Row className="pt-5 pb-2 px-5 last-updated-date">
					<Col>
						<label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(lastUpdatedDate)}</label>
					</Col>
				</Row>
			}
		</section >
	)
};

export default withRouter(DesktopView);