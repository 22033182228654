import { combineReducers } from "redux";

// Front
import IncentiveFinderByVehicle from "./IncentiveFinderByVehicle/reducer";
import IncentiveFinderByAll from "./IncentiveFinderByAll/reducer";
import IncentiveFinderFilters from "./IncentiveFinderFilters/reducer";
import EVIncentiveFinderFilters from "./EVIncentiveFinderFilters/reducer";
import IncentiveFinderCheckCredentials from "./IncentiveFinderCheckCredentials/reducer";
import EVIncentiveFinder from "./EVIncentiveFinder/reducer";
import EVIncentiveDetail from "./EVIncentiveDetail/reducer";
import UserDetails from "./Authentication/reducer";
import AuthPopupState from "./AuthPopupState/reducer";
import CompareVehicles from "./CompareVehicles/reducer";
import IncentiveSummary from "./IncentiveSummary/reducer";
import IncentiveDetailSearch from "./IncentiveDetailSearch/reducer";
import IncentiveDetailSearchFilters from "./IncentiveDetailSearchFilters/reducer";

const rootReducer = combineReducers({
    IncentiveFinderByVehicle,
    IncentiveFinderByAll,
    IncentiveFinderFilters,
    IncentiveFinderCheckCredentials,
    EVIncentiveFinderFilters,
    EVIncentiveFinder,
    EVIncentiveDetail,
    UserDetails,
    AuthPopupState,
    CompareVehicles,
    IncentiveSummary,
    IncentiveDetailSearchFilters,
    IncentiveDetailSearch
});


export default rootReducer;