
export const getLocalStorageData = (key) => {
    return localStorage.getItem(key);
}

export const setLocalStorageData = (key,values) => {
    localStorage.setItem(key,values);
}

export const removeLocalStorageData = (key) => {
    return localStorage.removeItem(key);
}


const sesstionKeynhame = "user_data";


export const getLoginUserData=()=>{
    let dd  = getLocalStorageData(sesstionKeynhame) || null;

    if( dd === null || dd === undefined || dd === "" ){
        return null;
    }else{

        try {
            dd = JSON.parse(dd);
        }
        catch(err) {
            console.log("json parse error ");
            dd = null;
        }

        return dd;
    }
}

export const setLoginUserData=(data)=>{
    setLocalStorageData( sesstionKeynhame ,JSON.stringify(data) );
}

export const clearLoginUserData=()=>{
    removeLocalStorageData(sesstionKeynhame);
}

