import { Fragment } from "react";
import { Spinner } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import Lottie from 'react-lottie';
import animation from '../assets/animation_file'

const PageLoaderComponent = ({
    loading
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return (
        <Fragment>
            {loading && (
                <div className="loader-container">
                    {/* <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <span>Loading...</span> */}
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
            )}
        </Fragment>
    );
}

export default withRouter(PageLoaderComponent);