import {
  INCENTIVE_DETAIL_SEARCH_FILTERS,
  SET_INCENTIVE_DETAIL_SEARCH_FILTERS_LOADING,
  UPDATE_INCENTIVE_DETAIL_SEARCH_FILTERS_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  status: false,
  message: "",
  error: "",
  data: false,
};

const IncentiveDetailSearchFilters = (state = initialState, action) => {

  switch (action.type) {
    case INCENTIVE_DETAIL_SEARCH_FILTERS:
      state = {
        ...state,
        //loading: true,
      };
      break;
    case SET_INCENTIVE_DETAIL_SEARCH_FILTERS_LOADING:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case UPDATE_INCENTIVE_DETAIL_SEARCH_FILTERS_DATA:
      state = {
        ...state,
        ...action.payload.data
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default IncentiveDetailSearchFilters;
