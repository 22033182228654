import React from "react";


const Basic404 = () => {

    return (
        <div>
            <h1>404 Page not found</h1>
        </div>
    );
}


export default Basic404;