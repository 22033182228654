import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useSelector, useDispatch } from "react-redux";
import {defaultPopUpState} from "../../helper/project_helper";
import { updateAuthPoupState } from "../../store/action";

export const AuthButton = ({ onLogin, children, className, ...props }) => {
    const [isLogin, userData] = useAuth();

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.statePopUpData,
    }));

    const dispatch = useDispatch();

    const CheckLogin = () => {
        if (isLogin) {
            onLogin(true);
        } else {
            dispatch(updateAuthPoupState({...defaultPopUpState,SignIn:true}, function(cc){
                onLogin(cc);
            }));
        }
    }

    
	useEffect(() => {
        if(statePopUpData?.signInSuccessCallback)
        {
            onLogin(true);
        }
    },[statePopUpData?.signInSuccessCallback]);

    return (
        <React.Fragment>
            <button onClick={(x) => {
                CheckLogin();
            }} className={className}>
                {children}
            </button>
        </React.Fragment>
    )
}


// setState({...defaultPropsLogin });
