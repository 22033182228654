import { withRouter, useLocation } from "react-router-dom";

const MyLink = ({
    to,
    className = '',
    children,
    history
}) => {
    const location = useLocation();

    const handelRedirect = () => {
        if (to === '/evincentivefinder' || to === '/') {
            window.location.href = to;
        } 
        else {
            history.push(to, true);
        }
    }

    return (
        <div className={"cursor-pointer " + className}
        onClick={handelRedirect}
        >
            {children}
        </div>
    );
}

export default withRouter(MyLink);