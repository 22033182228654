import {
  EV_INCENTIVE_FINDER,
  SET_EV_INCENTIVE_FINDER_LOADING,
  UPDATE_EV_INCENTIVE_FINDER_DATA,
} from "./actionTypes";


export const EV_incentive_valncentiveFinder = () => {
  return {
    type: EV_INCENTIVE_FINDER,
    payload: {},
  };
};

export const setEVIncentiveFinderLoading = (loading) => {
  return {
    type: SET_EV_INCENTIVE_FINDER_LOADING,
    payload: { loading },
  };
};

export const updateEVIncentiveFinder = (data) => {
  return {
    type: UPDATE_EV_INCENTIVE_FINDER_DATA,
    payload: { data },
  };
};
